<template>
  <div class="hello">
    <p>Copyright 2020-2030 中国青年创业就业基金会 版权所有 京ICP备16021889号</p>
    <p>技术支持：至德讯通（北京）科技有限公司 联系电话：13552946354</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  background: #eee;
  position: absolute;
  left: 50%;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  transform: translate(-50%, 0);
}
p {
  text-align: center;
  font-size: 12px;
  color: rgb(136, 135, 135);
}
</style>
