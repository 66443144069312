<template>
  <div class="score" ref="score">
    <div class="table2"></div>

    <div class="content_wrap">
      <!-- 项目详情 -->
      <div class="left_wrap">

        <div class="backBox">
          <div class="top" @click="backBox">返回列表</div>
        </div>
        <div class="content">
          <!-- 得分 -->
          <div class="conTop">
            <div class="topLeft">
              <span>参赛号：</span>
              <span>{{ number }}</span>
            </div>
            <div class="topBetween">
              <span>状态：</span>
              <span>{{
                  pingfen == 1
                      ? "未评审"
                      : pingfen == 2
                          ? "未提交"
                          : pingfen == 3
                              ? "已提交"
                              : ""
                }}</span>
            </div>
            <div class="topRight">
              <span>得分：</span>
              <span>{{ Number(score).toFixed(2) }}</span>
            </div>
          </div>
          <!-- 详情部分 -->
          <div class="conText">
            <div v-for="a in introduction" :key="a.id" v-show="is_v_show(a)">
              <!-- v-show="a.rules.title !== '性别' && a.rules.title !== '身份证号码' " -->
              <!-- 参赛地区到启动时间 -->
              <div class="contextLeft" v-if="a.type === 'text'">
                <span class="Left">{{ a.rules.title }}:</span>
                <span class="right">
              <div v-html="a.valueParse"></div>
            </span>
              </div>
              <!-- 项目主图 -->
              <!-- 专利、获奖、技术等级证书、证明 -->
              <div class="contextLeft" v-if="a.type === 'image'">
                <span class="Left">{{ a.rules.title }}:</span>
                <span class="right">
              <div v-for="(image, index) in a.valueParse" :key="index">
                <img
                    style="width: 115px; margin: 0 5px"
                    :src="$showUrl + image"
                    alt=""
                    @click="picPop(image)"
                />
              </div>
            </span>
              </div>
              <!-- 大量文字 -->
              <div class="contextLeft" v-if="a.type === 'textarea'">
                <span class="Left">{{ a.rules.title }}:</span>
                <span class="right">
              <span
                  :class="is_show == a.id ? 'right2' : 'right1'"
                  :style="{
                  height: is_show == a.id ? 'auto' : '',
                  dsiplay: 'inline-nlock',
                  width: '100%',
                }"
                  :id="a.refid"
              >
                <div v-html="a.valueParse" class="fwb" ></div>
              </span>
              <span class="zhankai" @click="zhankai(a.id)" v-if="a.stas">{{
                  is_show === a.id ? "收起" : "展开"
                }}</span>
            </span>
              </div>
              <!-- 文件 -->
              <div class="contextLeft" v-if="a.type === 'downLoad'">
                <span class="Left">{{ a.rules.title }}:</span>
                <span class="right" style="color: #4e80f5">
                   <div v-for="(file, index) in a.valueParse" :key="index" style="margin-bottom: 5px; display: flex;">
                      <span class="fileLeft">{{ file.name }}</span>
                      <span class="rightButton">
                  <button class="clickButton" @click="yulan(file.url)">
                    预览
                  </button>
                  <button class="clickButton" @click="xiazai(file.url)">
                    下载
                  </button>
                </span>
                   </div>
                </span>
              </div>
              <div class="contextLeft"
                   v-if="a.form_filed_type === 3 || a.form_filed_type === 5"
              >
                <span class="Left">{{ a.rules.title }}:</span>
                <span class="right" v-if="a.valueParse">
              <span>
                {{ a.valueParse.value == "其他" ? "" : a.valueParse.value }}
              </span>
              <span>{{ a.valueParse.other }}</span>
            </span>
              </div>
              <div class="contextLeft" v-if="a.type === 'selects'">
                <span class="Left">{{ a.rules.title }}:</span>
                <span class="right">
              <span v-for="(is, index) in a.valueParse.value" :key="index">
                {{ is == "其他" ? "" : is + ";" }}
              </span>
              <span>{{ a.valueParse.other }}</span>
            </span>
              </div>
            </div>
          </div>
          <!-- 底部操作按钮 -->
          <div class="buttonBottom">
            <el-button
                class="start"
                v-if="pingfen === '1' && type === '0'"
                @click="start()"
            >开始评分
            </el-button
            >
            <el-button
                class="modify"
                v-if="pingfen === '2' && type === '0'"
                @click="modify()"
            >修改评分
            </el-button
            >
            <!--            <el-button-->
            <!--                class="start"-->
            <!--                v-if="pingfen === '2' && type === '0'"-->
            <!--                @click="modify()"-->
            <!--            >提交-->
            <!--            </el-button-->
            <!--            >-->
            <el-button
                class="start"
                v-if="pingfen === '1' && type === '1'"
                @click="gorecom"
            >推荐
            </el-button
            >
            <el-button
                class="start"
                v-if="pingfen === '1' && type === '2'"
                @click="govote"
            >投票
            </el-button
            >
            <el-button
                class="start"
                v-if="(pingfen === '1' || pingfen === '2') && type === '3'"
                @click="recommend_score"
            >投票并赋分
            </el-button
            >
          </div>
        </div>
      </div>
      <!-- 评分明细列表  在右边边显示  -->
      <div class="right_box" v-show="showModal">
        <div class="right_wrap" v-show="showModal" id="right_wrap">
          <div class="title">
            <span class="title_left">请为该项目打分</span>
            <span class="title_right" @click="eidt()">关闭</span>
          </div>
          <div class="right_content" id="right_content">
            <!-- 评分明细列表 -->
            <div class="pfList">
              <ul>
                <li v-for="item in tableData" :key="item.id" style="list-style-type: none;margin-left: -30px">
                  <div class="item_wrap">
                    <div class="item_title">{{ item.name }}</div>
                    <div class="item_message">{{ item.description }}</div>
                    <div class="item_number_box">
                      <span class="item_score">得分:</span>
                      <el-input-number class="item_input" v-model="item.scores"
                                       :max="item.max_scores"
                                       :min="item.min_scores" :precision="2" @change="handleChange"></el-input-number>
                      <span class="item_min_max_scores">分值：{{ item.min_scores }} ~ {{ item.max_scores }}</span>
                    </div>
                  </div>
                </li>

              </ul>


            </div>
            <div class="tj_wrap" v-show="recommend_show">
              <!-- 是否开启推荐 -->
              <span class="tj_title">是否推荐：</span>
              <el-radio-group class="tj_message"  v-model="recommend_vote" @change="recommendChange">
                <el-radio :label="1" style="margin-top: 5px">是</el-radio>
                <el-radio :label="0" style="margin-top: 5px">否</el-radio>
              </el-radio-group>
            </div>
            <div class="tj_wrap" v-show="tsign_show">
              <!-- 是否开启签名 -->
              <span class="tj_title">评委签字：</span>
              <div class="tj_message">
                <el-row span="24" v-show="signBtnRowVisible">
                  <el-button type="primary" @click="showSignQRCode">{{ signatureBtnText }}</el-button>
                </el-row>
                <el-row span="24">
                  <el-dialog title="扫描后保留此窗口，在结果返回前关闭此窗口视为取消签字" :visible.sync="dialogVisible"
                             width="500px" append-to-body @opened="generateQRCode" @close="closeDialog"
                             :close-on-click-modal="false"
                             :close-on-press-escape="false"
                  >
                    <div class="center-flex" id="qrCode" ref="qrCodeDiv"></div>
                    <span slot="footer" class="dialog-footer">
                      <el-button @click="handleCloseDialog">取 消</el-button>
                    </span>
                  </el-dialog>
                </el-row>
                <el-row span="24" v-show="signature_img != ''">
                  <div class="demo-image__preview">
                    <el-image v-show="signature_img != ''"
                        style="width: 200px; height: 100px"
                        fit="container"
                        :src="signature_img == '' ? imgDefault : $showUrl + signature_img"
                        :preview-src-list="imgPreviewList">
                    </el-image>
                  </div>
                </el-row>

              </div>
            </div>

            <div class="tj_wrap">
              <span class="tj_title">评审意见：</span>
              <div class="tj_message" style="position: relative">
                <el-input
                    type="textarea"
                    id="textarea"
                    style="font-size: 14px;line-height: 30px"
                    :rows="3"
                    placeholder="请输入评审意见"
                    maxlength="500"
                    @input="inputSize"
                    v-model="assessment">
                </el-input>
                <label for="textarea" style="color: #cccccc;font-size: 12px; position: absolute;right: 15px;bottom: 5px">{{ titleMaxLengh }}</label>
              </div>
            </div>
            <!-- 下拉选项 -->
<!--            <div class="tj_wrap" style="display: flex;flex-direction: row" v-show="reason_show">-->
<!--              <span class="tj_title">不推荐原因：</span>-->
<!--              <el-radio-group v-model="value" style="margin-top: 10px" @change="reasonChange">-->
<!--                <div v-for="item in optionsList" style="width: 100%; line-height: 30px">-->
<!--                  <el-radio :label="item.label" :value="item.value" style="white-space: normal;line-height: 30px;margin-right: 0;width: 90%;text-indent: -12px;padding-left: 20px"></el-radio>-->
<!--                </div>-->
<!--              </el-radio-group>-->
<!--            </div>-->
          </div>

          <!-- 底部功能按钮：保存， 提交 -->
          <div class="popBottom">
            <!-- <el-button class="save" @click="eidt()">取消</el-button> -->
            <el-button class="submit" @click="save()">保存</el-button>
            <el-button class="save" @click="submit()">提交</el-button>
          </div>
        </div>
      </div>
    </div>
    <Helloworld></Helloworld>
    <!-- 打分弹出框 -->
    <!--        <div class="shadow" v-show="showModal">-->
    <!--          <div class="showModal">-->
    <!--            <div class="edit" @click="eidt()">X</div>-->
    <!--            <div class="popTop">请为该项目打分数</div>-->
    <!--            <div class="popContent">-->
    <!--              <el-table-->
    <!--                  :data="tableData"-->
    <!--                  style="width: 100%"-->
    <!--                  :cell-style="changeCellStyle"-->
    <!--                  height="400"-->
    <!--              >-->
    <!--                <el-table-column prop="name" label="评分项" width="120">-->
    <!--                </el-table-column>-->
    <!--                <el-table-column prop="description" label="描述"></el-table-column>-->
    <!--                <el-table-column prop="range" label="分值范围" align="center">-->
    <!--                  <template slot-scope="scope">-->
    <!--                    <span>{{ scope.row.min_scores }}</span> <i>~</i>-->
    <!--                    <span>{{ scope.row.max_scores }}</span>-->
    <!--                  </template>-->
    <!--                </el-table-column>-->
    <!--                <el-table-column prop="score" label="得分" align="center">-->
    <!--                  <template slot-scope="scope">-->
    <!--                    <el-input-number-->
    <!--                        v-model="scope.row.scores"-->
    <!--                        :min="Number(scope.row.min_scores)"-->
    <!--                        :max="Number(scope.row.max_scores)"-->
    <!--                        label="请打分"-->
    <!--                        :precision="2"-->
    <!--                        controls-position="right"-->
    <!--                    ></el-input-number>-->

    <!--                    &lt;!&ndash; <el-input-->
    <!--                      type="number"-->
    <!--                      v-model="scope.row.scores"-->
    <!--                      :min="Number(scope.row.min_scores)"-->
    <!--                      placeholder="请打分"-->
    <!--                      @blur="valid(scope.row)"-->
    <!--                    ></el-input> &ndash;&gt;-->
    <!--                  </template>-->
    <!--                </el-table-column>-->
    <!--              </el-table>-->
    <!--              <div style="margin: 10px" v-show="recommend_show">-->
    <!--                <span style="padding-right: 15px">是否推荐：</span>-->
    <!--                <el-radio-group v-model="recommend_vote">-->
    <!--                  <el-radio :label="1">是</el-radio>-->
    <!--                  <el-radio :label="0">否</el-radio>-->
    <!--                </el-radio-group>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="popBottom">-->
    <!--              &lt;!&ndash; <el-button class="save" @click="eidt()">取消</el-button> &ndash;&gt;-->
    <!--              <el-button class="submit" @click="save()">保存</el-button>-->
    <!--              <el-button class="save" @click="submit()">提交</el-button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!-- 打分提示弹出框 -->
    <div class="shadow" v-show="pop">
      <div class="pop">
        <div class="img">
          <div class="image">
            <img src="../assets/image/over.png" alt=""/>
          </div>
        </div>
        <div class="success">
          {{ type == 0 ? "评分成功！" : type == 1 ? "推荐成功！" : type == 2 ? "投票成功！" : "评分成功！" }}
        </div>
        <div class="totalScore" v-if="type == 0">
          <span>总得分：</span>
          <span class="totalNum">{{ Number(score).toFixed(2) }}</span
          ><span>分</span>
        </div>
        <div class="buttomPop">
          <!-- <el-button class="next" @click="next()">下一个项目</el-button> -->
          <el-button class="back" @click="back()">返回列表</el-button>
        </div>
      </div>
    </div>
    <!-- 推荐并打分 -->
    <div class="shadow" v-show="recommend_score_window">
      <div class="showModal">
        <div class="edit" @click="edit_recommend_score()">X</div>
        <div class="popTop">投票并赋分</div>
        <div class="popContent">
          <el-form
              ref="recommend_score_form"
              :model="recommend_score_form"
              label-width="80px"
              :rules="form_rules"
          >
            <el-form-item label="是否投票" prop="recommend">
              <el-radio-group v-model="recommend_score_form.recommend">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="项目得分" v-show="isRecommend" prop="score">

              <el-input
                  v-model="recommend_score_form.score"
                  @change="testNumber"
                  type="text"
                  @input="(v)=>(recommend_score_form.score = v.replace(/[^\d]/g,''))"
                  :placeholder="min_maxScore"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="popBottom">
          <el-button
              class="save"
              @click="recommend_score_save('recommend_score_form')"
          >保存
          </el-button
          >
          <el-button
              class="submit"
              @click="recommend_score_submit('recommend_score_form')"
          >提交
          </el-button
          >
        </div>
      </div>
    </div>

    <!-- 附件在线预览 -->
    <div>
      <div ref="file"></div>
    </div>

  </div>
</template>

<script>
import Helloworld from "@/components/HelloWorld.vue";
import download from "downloadjs";
import axios from "axios";
import QRCode from "qrcodejs2";


export default {
  components: {Helloworld},
  data() {
    //校验得分为范围内
    let checkScore = (rule, value, callback) => {
      //如果推荐，则校验项目得分;不推荐，不校验
      if (this.isRecommend == true) {
        if (value < this.minScore || value > this.maxScore) {
          // this.recommend_score_form.score = 100.00;
          return callback(
              new Error(
                  "请输入得分范围内" +
                  "(" +
                  this.minScore +
                  "—" +
                  this.maxScore +
                  ")"
              )
          );
        } else {
          callback();
        }
      } else {
        callback();
        return true;
      }
    };

    return {
      id: 0, //项目id
      pingfen: "", //评分按钮
      number: "PL230568987", //编号
      state: "待评审", //状态
      score: 0, //分数
      type: "", //类型
      introduction: [], //描述
      list: [
        {
          id: 1,
          enclosure:
              "甘肃省欣欣向荣艺术文化传播有限公司“丝绸之路，飞天艺术”商业计划书.ppt",
        },
        {
          id: 2,
          enclosure:
              "甘肃省欣欣向荣艺术文化传播有限公司“丝绸之路，飞天艺术”商业计划书.ppt",
        },
      ],
      tableData: [], //评分规则
      showModal: false,
      pop: false,
      is_show: -1,
      picturePop: false,
      imgUrl: "",
      expande: true,
      needShowExpande: false,
      recommend_score_window: false, //推荐并打分窗口
      recommend_score_form: {
        recommend: "", //是否投票 1投票(是),0不投票(否)
        score: 0, //项目得分
      },
      isRecommend: false, //投票显示分数，不投票不显示
      minScore: 0, //得分最小值
      maxScore: 0, //得分最大值
      min_maxScore: "", //分数范围
      recommend_vote: 0, //默认不推荐，
      recommend_show: false, //默认不开启推荐功能，
      tsign_show: false, //默认不开启推荐功能，
      reason_show: false, //不推荐的原因列表
      reason_name: '', //不推荐原因名称
      assessment:'',   //评审意见
      titleMaxLengh :500,
      //表单校验
      form_rules: {
        recommend: [
          {required: true, message: "请选择是否推荐", trigger: "change"},
        ],
        score: [{validator: checkScore, trigger: "blur"}],
      },

      //不推荐下拉选项list
      optionsList: [{
        value: '1',
        label: '选题意义原因，如选题不当、不符合立项条件没有创新性、没有学术价值、重复性选题等'
      }, {
        value: '2',
        label: '研究基础方面原因，如没有相关成果、不了解研究现状、所列文献没有代表性等'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      dialogVisible: false,
      signToken: '',//用于存储签字token
      signature_img: '',//用于存储签字照片
      imgPreviewList: [],
      intervalId: null, // 用于存储interval的id
      timeoutId: null, // 用于存储timeout的id
      signatureBtnText: "签字",
      imgDefault:require('@/assets/image/image.png'),
      signBtnRowVisible: true //是否显示签字按钮
    };
  },
  watch: {
    "recommend_score_form.recommend": {
      handler(newVal) {
        if (newVal === "1") {
          this.isRecommend = true;
        } else {
          this.isRecommend = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.pingfen = this.$route.query.pingfen;
    this.type = this.$route.query.type;
    this.getplaydetail(); //详情
    this.getRule(); //评分规则
    console.log(this.signature_img)
    // 赛事列表最小高度
    let height = document.querySelector(".header").clientHeight;
    document.querySelector(".table2").style.height = height + "px";
    this.$refs.score.style.minHeight =
        document.querySelector("body").clientHeight - height + "px";
    window.onresize = () => {
      this.changeDivHeight();
    };
    this.changeDivHeight();

  },
  methods: {

    test(num){
      num = num.toString();
      var  reg = "/^((?|0)\d{1,9})$/";
      if (!num.match(reg)){
        return false;
      } else {
        return true;
      }
    },
    // 审核控制输入框字数不超过500
    inputSize(){
      let txtLength = this.assessment.length;
      this.titleMaxLengh = 500-txtLength;
      console.log("还可以输入："+ this.titleMaxLengh+"个字");

    },
    //是否推荐单选变更，如果选不推荐，则显示不推荐原因，如果选择推荐，则不显示推荐原因
    recommendChange(val) {
      if (val === 1) {
        this.reason_show = false;
        this.reason_name = '';
      } else {
        this.reason_show = true;
      }
    },
    //不推荐原因选择变化
    reasonChange(val) {
      this.reason_name = val;
      console.log("====我现在了：" + val);
    },
    //获取屏幕尺寸
    changeDivHeight() {
      //获取浏览器窗口的高度
      var winHeight = 0;
      if (window.innerHeight) {
        winHeight = window.innerHeight;
      } else if ((document.body) && (document.body.clientHeight)) {
        winHeight = document.body.clientHeight;
      } else if (document.documentElement && document.documentElement.clientHeight) {
        winHeight = document.documentElement.clientHeight;
      }
      document.getElementById("right_content").style.height = winHeight * 0.65 + "px";
      document.getElementById("right_wrap").style.height = winHeight * 0.8 + "px"
    },
    // 判断是否隐藏显示字段
    is_v_show(a) {
      switch (a.form_id) {
        case 114:
          // 钟南山项目
          if (a.rules.title === '姓名'
              || a.rules.title === '推荐渠道'
              || a.rules.title === '籍贯'
              || a.rules.title === '身份证号码'
              || a.rules.title === '工作单位及行政职务'
              || a.rules.title === '单位性质'
              || a.rules.title === '通信地址'
              || a.rules.title === '单位所在地'
              || a.rules.title === '邮政编码'
              || a.rules.title === '单位电话'
              || a.rules.title === '手机号'
              || a.rules.title === '传真号码'
              || a.rules.title === '电子信箱'
              || a.rules.title === '主要学历'
              || a.rules.title === '证明材料'
              || a.rules.title === '工作单位意见'
              || a.rules.title === '推荐单位意见'
              || a.rules.title === '其他附件材料'
              || a.rules.title === '推荐专家') {
            return false;
          } else {
            return true;
          }
          break;
        case 116:
          if (a.rules.title === '申请人信息'
              || a.rules.title === '负责人信息') {
            return false;
          } else {
            return true;
          }
          break;
        case 115:
          if (a.rules.title === '申请人信息'
              || a.rules.title === '负责人信息') {
            return false;
          } else {
            return true;
          }
          break;
        default:
          return true;

      }
    },
    //得分失去焦点时或者按回车时，保留两位小数
    testNumber(value) {
      //判断是否超出范围，超出范围显示最大值
      let testValue;
      if (value > this.maxScore) {
        testValue = this.maxScore
      } else if (value < this.minScore) {
        testValue = this.minScore;
      }
      let temp = Number(testValue).toFixed(0);
      //如果输入数字保留两位小数，则不变；没有保留两位小数，则保留两位小数
      this.recommend_score_form.score =
          temp == testValue
              ? testValue
              : parseFloat(this.recommend_score_form.score).toFixed(0);
    },
    //推荐并打分，保存按钮
    recommend_score_save(recommend_score_form) {
      //表单校验
      this.$refs[recommend_score_form].validate((valid) => {
        if (valid) {
          let form = new FormData();
          let temp = this.tableData[0]; //临时存储变量
          //判断推荐或不推荐,推荐：正常得分;不推荐:1
          if (this.recommend_score_form.recommend === 0) {
            this.recommend_score_form.score = 0;
          }
          form.append("token", sessionStorage.getItem("token"));
          form.append(`scores[0][rule_id]`, temp.rule_id);
          form.append(`scores[0][id]`, temp.id);
          form.append(`scores[0][project_id]`, temp.project_id);
          form.append(
              `scores[0][is_vote]`,
              Number(this.recommend_score_form.recommend)
          );
          form.append(`scores[0][scores]`, this.recommend_score_form.score);
          // console.log(Array.from(form),"接口数据");
          this.$api
              .recommendAndScoresCommit(form)
              .then((res) => {
                // console.log(res, "保存结果");
                if (res.status == 200) {
                  this.$message.success("保存成功！");
                  this.$router.go(-1);
                }
                this.recommend_score_window = false;
              })
              .catch((err) => {
                this.$message.error("保存失败，请检查网络");
              });
        } else {
          this.$message.warning("请重新输入项目得分！");
          return false;
        }
      });
    },
    //推荐并打分，提交按钮
    recommend_score_submit(recommend_score_form) {
      this.$refs[recommend_score_form].validate((valid) => {
        if (valid) {
          let form = new FormData();
          let temp = this.tableData[0]; //临时存储变量
          form.append("token", sessionStorage.getItem("token"));
          form.append(`scores[0][rule_id]`, temp.rule_id);
          form.append(`scores[0][id]`, temp.id);
          form.append(`scores[0][project_id]`, temp.project_id);
          form.append(
              `scores[0][is_vote]`,
              Number(this.recommend_score_form.recommend)
          );
          form.append(`scores[0][scores]`, this.recommend_score_form.score);
          // console.log(Array.from(form),"接口数据");
          this.$api
              .recommendAndScoresPush(form)
              .then((res) => {
                // console.log(res, "提交结果");
                if (res.status == 200) {
                  this.$message.success("提交成功！");
                  this.$router.go(-1);
                }
                this.recommend_score_window = false;
              })
              .catch((err) => {
                this.$message.error("提交失败，请检查网络");
              });
        } else {
          return false;
        }
      });
    },
    //推荐并打分关闭窗口
    edit_recommend_score() {
      this.recommend_score_window = false;
    },
    //推荐并打分按钮
    recommend_score() {
      this.recommend_score_window = true;
      this.recommend_score_form.score = this.tableData[0].scores.toFixed(2);
      //修改推荐并打分时，显示保存的推荐结果和项目得分
      if (this.tableData[0].scores != 0 && this.$route.query.pingfen === "2") {
        this.recommend_score_form.recommend = "1";
      }
      // this.recommend_score_form.recommend = "";
    },

    // tab输入框校验
    valid(val) {
      val.scores = val.scores.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;
      // 只能是数字和小数点，不能是其他输入
      val.scores = val.scores.replace(reg, "");
      // 保证第一位只能是数字，不能是点
      val.scores = val.scores.replace(/^\./g, "");
      // 小数只能出现1位
      val.scores = val.scores
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
      // 小数点后面保留2位
      val.scores = val.scores.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      if (Number(val.scores) > 0) {
        val.scores = val.scores.replace(/^[0]/g, "");
      }
      if (
          Number(val.scores) < val.min_scores ||
          Number(val.scores) > val.max_scores
      ) {
        Number(val.scores) < val.min_scores
            ? (val.scores = Number(val.min_scores))
            : (val.scores = Number(val.max_scores));
        this.$message.error("请输入分值范围内的分数");
      }
    },

    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "评分项") {
        return "font-weight: 600;font-size: 16px;";
      } else {
        return "";
      }
    },
    zhankai(val) {
      if (this.is_show == val) {
        this.is_show = -1;
      } else {
        this.is_show = val;
      }
    },
    // 预览--文件
    yulan(url) {
      // window.location.href = url
      window.open(this.$showUrl + url);
    },
    // 下载--文件
    xiazai(url) {
      download(this.$showUrl + url);
    },

    // 获取详情
    getplaydetail() {
      let data = {
        project_id: this.id,
        token: sessionStorage.getItem("token"),
      };
      this.$api.playdetail(data).then(
          ({
             data: {
               data: {code, field, status, scores},
             },
           }) => {
            this.state = status; //状态
            this.number = code; //参赛号码
            this.score = scores; //分数
            // 不同显示
            field.forEach((it) => {
              if (it.value) {
                // if (it.form_filed_type === 10
                // ) {
                //   let arr = it.value.split('^')
                //   let imgArr = []
                //   arr.forEach(is => {
                //     imgArr.push(is.split(';')[1])
                //   })
                //   it.value = imgArr
                // }
                // if (
                //   it.form_filed_type === 11
                // ) {
                //   it.value = JSON.parse(it.value);
                // }
                // if (it.rules.title === "行业分类") {
                //   it.value = JSON.parse(it.value).value;
                // }
                // if (it.rules.title === "申报人信息") {
                //   it.value = JSON.parse(it.value);
                // }
                // if (it.rules.title === "申报人信息") {
                //   let a = ''
                //   it.value.forEach((is, index) => {
                //     a += `
                //     <div style="display:flex;line-height: 30px;">
                //       ${it.value[index].name},${it.value[index].sex},${it.value[index].zjtype},${it.value[index].zjh}, ${it.value[index].zw},${it.value[index].birth};
                //     </div>`;
                //   })
                //   it.value = a
                // }
                // if (it.rules.title === "参赛地区") {
                //   this.getAddress(it.value).then((rews) => {
                //     it.value = rews;
                //   });
                // }
                // 项目名称
                if (
                    it.form_filed_type === 50 ||
                    it.form_filed_type === 6 ||
                    it.form_filed_type === 1 ||
                    it.form_filed_type === 7 ||
                    it.form_filed_type === 8 ||
                    it.form_filed_type === 17 ||
                    it.form_filed_type === 20 ||
                    it.form_filed_type === 22 ||
                    it.form_filed_type === 26 ||
                    it.form_filed_type === 27 ||
                    it.form_filed_type === 28 ||
                    it.form_filed_type === 29 ||
                    it.form_filed_type === 30 ||
                    it.form_filed_type === 33 ||
                    it.form_filed_type === 36 ||
                    it.form_filed_type === 37 ||
                    it.form_filed_type === 50 ||
                    it.form_filed_type === 51 ||
                    it.form_filed_type === 52 ||
                    it.form_filed_type === 53 ||
                    it.form_filed_type === 54 ||
                    it.form_filed_type === 55
                ) {
                  // 直接显示
                  it.valueParse = it.value;
                  it.type = "textarea";
                  it.refid = "pid-" + it.id;
                }
                // 大量文字
                if (it.form_filed_type === 2 || it.form_filed_type === 56 ) {
                  // 直接显示
                  it.valueParse = it.value;
                  it.type = "textarea";
                  it.refid = "pid-" + it.id;
                  this.$nextTick(() => {
                    let refhight = document.querySelector("#" + it.refid);
                    if (refhight.offsetHeight >= 90) {
                      this.$set(it, "stas", true);
                    } else {
                      this.$set(it, "stas", false);
                    }
                  });
                }
                // 文件
                if (it.form_filed_type === 11 || it.form_filed_type === 12) {
                  // 直接显示
                  // console.log(it.value);
                  it.valueParse = JSON.parse(it.value);
                  it.type = "downLoad";
                }
                // 地区
                if (it.form_filed_type === 32) {
                  it.type = "diqu";
                  it.valueParse = JSON.parse(it.value);
                  this.getAddress(it.value).then((rews) => {
                    it.valueParse = rews;
                  });
                  it.type = "text";
                }
                // 联系地址 ---- 未改    40 =-- 未改
                if (it.form_filed_type === 25) {
                  it.valueParse = JSON.parse(it.value);
                  this.getAddress(JSON.stringify(it.valueParse.areaid)).then(
                      (rews) => {
                        it.valueParse = rews + " - " + it.valueParse.info;
                      }
                  );
                  it.type = "text";
                }
                //自定义组件
                if (it.form_filed_type === 40) {
                  let tab = JSON.parse(it.value);
                  // console.log(it.valueParse);
                  let a = "";
                  tab.forEach((is, index) => {
                    let b = "";
                    it.rules.list.forEach((lg) => {
                      b += `<div style='margin-right:10px'>${lg.title} : ${
                          is[lg.num]
                      } ; </div>`;
                    });
                    // console.log(b);
                    b = b.slice(0, -1);
                    a += ` <div style="line-height: 30px;">
                    ${b}
                  </div>`;
                  });
                  it.valueParse = a;
                  it.type = "text";
                }
                // 个人信息列表
                if (it.form_filed_type === 16) {
                  it.valueParse = JSON.parse(it.value)
                  let a = ''
                  it.valueParse.forEach((is, index) => {
                    // console.log(is, "惹怒我");
                    let b = ''
                    for (const key in is) {
                      b += `${is[key]},`
                    }
                    // console.log(b);
                    b = b.slice(0, -1)
                    a += `
                    <div style="display:flex;line-height: 30px;">
                     ${b};
                    </div>`;
                  })
                  it.valueParse = a
                  it.type = "text"
                }
                // 电话
                if (it.form_filed_type === 18) {
                  it.valueParse = JSON.parse(it.value);
                  let a = `${it.valueParse.mobtype} ${it.valueParse.phone}`;
                  it.valueParse = a;
                  it.type = "text";
                }
                // 证件号
                if (it.form_filed_type === 19) {
                  it.valueParse = JSON.parse(it.value);
                  let a = `${it.valueParse.zjtype} ${it.valueParse.value}`;
                  it.valueParse = a;
                  it.type = "text";
                }
                if (it.form_filed_type === 10) {
                  let arr = it.value.split("^");
                  let imgArr = [];
                  arr.forEach((is) => {
                    imgArr.push(is.split(";")[1]);
                  });
                  it.valueParse = imgArr;
                  it.type = "image";
                }
                // 下拉，单选
                if (it.form_filed_type === 3 || it.form_filed_type === 5) {
                  if (it.value === "") {
                    it.valueParse = {
                      value: "",
                      other: "",
                    };
                  } else {
                    it.valueParse = JSON.parse(it.value);
                  }
                  // console.log(it.valueParse);
                }
                // 下拉，单选
                if (it.form_filed_type === 4) {
                  it.valueParse = JSON.parse(it.value);
                  it.type = "selects";
                }
              }
            });
            this.introduction = field; //详情表单

          }
      );
    },
    // 预览--图片
    picPop(val) {
      window.open("/#/imgurl?url=" + val);
    },
    // 推荐
    gorecom() {
      this.$confirm("是否推荐", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "推荐",
        cancelButtonText: "不推荐",
        type: "warning",
      })
          .then(async () => {
            // 点击推荐
            let formdata = new FormData();
            formdata.append("token", sessionStorage.getItem("token"));
            formdata.append("project_id", this.id);
            let {data} = await this.$api.tuijian(formdata);
            if (data.code == 1) {
              this.pop = true;
              return;
            }
            this.$message.error(data.msg);
          })
          .catch(async (err) => {
            if (err === "close") {
            } else {
              // 不推荐
              let formdata = new FormData();
              formdata.append("token", sessionStorage.getItem("token"));
              formdata.append("project_id", this.id);
              let res = await this.$api.deltuijian(formdata);
              if (res.data.code === 1) {
                this.$message.success("已取消推荐");
                this.$router.go(-1);
              }
            }
          });
    },
    // 投票
    async govote() {
      let formdata = new FormData();
      formdata.append("token", sessionStorage.getItem("token"));
      formdata.append("project_id", this.id);
      let {data} = await this.$api.vote(formdata);
      if (data.code == 1) {
        this.pop = true;
        return;
      }
      this.$message.error(data.msg);
    },

    // 获取评分规则
    getRule() {
      let data = {
        token: sessionStorage.getItem("token"),
        project_id: this.id,
      };
      this.$api.Rule({params: data}).then((res) => {

        this.tableData = res.data.data.rule_index;
        console.log("评分规则:" + res.data.data.is_trecommend);
        // 判断是否推荐是否开启，如果是1，则开启，0 则为不开启
        if (res.data.data.is_trecommend !== 0) {
          this.recommend_show = true;
        } else {
          this.recommend_show = false;
        }
        // 判断是否开启签字，如果是1，则开启，0 则为不开启，如果评委已经签过字，就不需要要再签字了
        if (res.data.data.is_tsign !== 0) {
          this.tsign_show = true;
          this.signToken = res.data.data.signature_token;
          this.signature_img = res.data.data.signature_img;
        } else {
          this.tsign_show = false;
        }
        if(res.data.data.signature_img != '') {
          this.hideSignBtn();
        }

        this.recommend_vote = res.data.data.is_recommend;
        this.assessment = res.data.data.opinion;
        let temp = res.data.data.rule_index;
        this.maxScore = temp[0].max_scores;
        this.minScore = temp[0].min_scores;
        this.min_maxScore = temp[0].min_scores + "——" + temp[0].max_scores;

        if (res.data.data.is_recommend === 0) {
          this.reason_show = true;

        }
      });
    },
    //评分输入框数字更改时触发
    handleChange(value) {
      let num = 0;
      this.tableData.forEach((item, index) => {
        num += Number(item.scores);
      });
      this.score = num;

    },
    // 开始评分--打开评分规则弹窗
    start() {
      this.showModal = true;
    },
    // 修改评分 -- 评分规则弹窗
    modify() {
      this.showModal = true;
    },
    // 提交评分
    submit() {
      if (this.recommend_vote == 0 && this.assessment.length < 25) {
        return this.$message.error("请填写评审意见，至少25个字");
      }
      if (this.tsign_show == true && this.signature_img == '') {
        return this.$message.error("提交前需要评委签字");
      }
      this.$confirm("提交后成绩不可修改，请谨慎提交。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let form = new FormData();
            let num = 0;
            form.append("token", sessionStorage.getItem("token"));
            this.tableData.forEach((item, index) => {
              num += Number(item.scores);
              form.append(`scores[${index}][rule_id]`, item.rule_id);
              form.append(`scores[${index}][id]`, item.id);
              form.append(`scores[${index}][project_id]`, item.project_id);
              form.append(`scores[${index}][scores]`, item.scores);

            });
            form.append("is_recommend", this.recommend_vote);
            form.append("opinion",this.assessment);
            if (this.tsign_show == true) {
              form.append("signature", this.signature_img);
            }
            this.$api
                .scores(form)
                .then((res) => {
                  this.showModal = false;
                  this.pop = true;
                  this.score = num;
                })
                .catch((err) => {
                  this.$message.error("评分失败，请检查网络");
                });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消提交",
            });
          });
    },
    // 保存评分
    save() {
      if (this.recommend_vote == 0 && this.assessment.length < 25) {
        return this.$message.error("请填写评审意见，至少25个字");
      }
      if (this.tsign_show == true && this.signature_img == '') {
        return this.$message.error("提交前需要评委签字");
      }
      let form = new FormData();
      let num = 0;

      form.append("token", sessionStorage.getItem("token"));
      this.tableData.forEach((item, index) => {
        num += Number(item.scores);
        form.append(`scores[${index}][rule_id]`, item.rule_id);
        form.append(`scores[${index}][id]`, item.id);
        form.append(`scores[${index}][project_id]`, item.project_id);
        form.append(`scores[${index}][scores]`, item.scores);
      });
      form.append("is_recommend", this.recommend_vote);
      form.append("opinion",this.assessment);
      if (this.tsign_show == true) {
        form.append("signature", this.signature_img);
      }
      // console.log(Array.from(form), "接口数据");
      // return;
      this.$api
          .scoresCommit(form)
          .then((res) => {
            this.showModal = false;
            this.pop = true;
            this.score = num;
          })
          .catch((err) => {
            this.$message.error("评分失败，请检查网络");
          });
    },
    // 下一个项目
    next() {
      this.pop = false;
    },
    // 取消
    eidt() {
      this.showModal = false;
    },
    // 返回列表
    back() {
      this.$router.go(-1);
    },
    backBox() {
      this.$router.go(-1);
    },

    // 获取地址根据code获取地区名
    async getAddress(val) {
      let a = JSON.parse(val);
      let form = new FormData();
      form.append("code", a[a.length - 1]);
      let {
        data: {data},
      } = await this.$api.addstr(form);
      return data;
    },

    // 计算文字 显示展开 收起
    calculateText() {
      // 获取一行文字的height 计算当前文字比较列表文字
      let oneHeight = this.$refs.more.scrollHeight;
      let twoHeight = oneHeight * 2 || 40;
      this.textHeight = `${twoHeight}px`;
      let txtDom = this.$refs.textContainer;
      for (let i = 0; i < txtDom.length; i++) {
        let curHeight = txtDom[i].offsetHeight;
        if (curHeight > twoHeight) {
          this.$set(
              this.historyList,
              i,
              Object.assign({}, this.historyList[i], {status: true})
          );
        } else {
          this.$set(
              this.historyList,
              i,
              Object.assign({}, this.historyList[i], {status: null})
          );
        }
      }
    },
    more(index) {
      this.historyList[index].status = !this.historyList[index].status;
    },
    generateQRCode () {
      this.$nextTick(()=>{
        this.$refs.qrCodeDiv.innerHTML = "";
        const qrcode =   new QRCode(this.$refs.qrCodeDiv, {
          text: window.location.origin  + '/' + this.$router.resolve({
            name: 'signName',
            params: { token: this.signToken }
          }).href,
          // text: 'http://cmpmatchexpert.digilinx.net.cn'  + '/' + this.$router.resolve('/signName').href,
          width: 150,
          height: 150,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
      })
    },
    spollGetSignature() {
      let form = new FormData();
      form.append("token", this.signToken);
      this.$api.getSignature(form).then((res) => {
        if (res.data.code == 1 && res.data.data.signature_img) {
          this.signature_img = res.data.data.signature_img;
          this.imgPreviewList = [];
          this.imgPreviewList.push(this.$showUrl + res.data.data.signature_img);
          this.handleCloseDialog();
        }
      }).catch((err) => {
        // console.log(err)
        this.$message.error("请求失败，请检查网络");
      });
    },
    showSignQRCode() {
      this.handleShowDialog();
      this.startPolling();
    },
    startPolling() {
      // 设置interval，每10秒调用一次
      this.intervalId = setInterval(() => {
        this.spollGetSignature();
      }, 10 * 1000);//每隔10秒轮询

      // 设置timeout，15分钟后清除interval
      this.timeoutId = setTimeout(() => {
        this.timeoutPolling();
      }, 30 * 60 * 1000);//30分钟后超时
    },
    timeoutPolling() {
        this.stopPolling()
        this.$message.error("等待签字时间超时，请重新操作");
        this.handleCloseDialog();
    },
    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    stopTimeout() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    handleCloseDialog() {
      this.dialogVisible = false;
      this.stopTimeout();
      this.stopPolling();
    },
    handleShowDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      //签字对话框关闭后，需要停止轮询，并且更新签字token
      this.stopPolling();
      this.stopTimeout();
      this.refreshSignToken();
      this.showResignBtn();
    },
    showResignBtn() {
      this.signatureBtnText = "重签"
    },
    hideSignBtn() {
      this.signBtnRowVisible = false
    },
    refreshSignToken() {
      let form = new FormData;
      form.append("token", sessionStorage.getItem("token"));
      this.$api
          .getNewSignatureToken(form)
          .then((res) => {
            // console.log(res, "保存结果");
            if (res.data.code == 1 && res.data.data.signature_token) {
              this.signToken = res.data.data.signature_token;
            }
          })
          .catch((err) => {
            console.log('刷新token失败')
          });
    }
  },
  beforeDestroy() {
    this.stopPolling(); // 组件销毁前清除interval
    this.stopTimeout(); // 组件销毁前清除timeout
  }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1030px) {
  .showModal {
    width: 75% !important;
    top: 20% !important;
    left: 12% !important;
  }
}

@media screen and (max-width: 1000px) {
}

.score {
  width: 100%;
  background-color: #eeeeee;
  position: relative;
  padding-bottom: 80px;

  .content_wrap {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    text-align: center;

    .left_wrap {
      width: 65%;
      height: auto;
      margin: 0 auto;

      .backBox {
        width: 100%;
        //margin: 0 auto;

        .top {
          padding-top: 30px;
          color: #4e80f5;
          font-size: 14px;
          text-align: left;

        }

        .top:hover {
          color: #4e80f5;
          cursor: pointer;
        }
      }

      .content {
        width: 100%;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 15px;
        background-color: #fff;
        padding-bottom: 10px;

        .conTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          height: 45px;
          background-color: #eef3fe;
          font-weight: 600;

          .topLeft {
            display: flex;
            width: 33%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .topBetween {
            display: flex;
            width: 33%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .topRight {
            display: flex;
          }
        }

        .conText {
          width: 100%;

          .contextLeft {
            display: flex;
            margin: 20px 0;
            justify-content: space-between;
            padding: 0 20px 0 10px;

            .Left {
              width: 30%;
              text-align: right;
              font-weight: 600;
              margin-top: 5px;
            }

            .right {
              width: 75%;
              word-wrap: break-word;
              padding-right: 30px;
              text-align: justify;
              margin-left: 15px;

              .right1 {
                width: 100%;
                word-wrap: break-word;
                padding-right: 30px;
                text-align: justify;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 30px;
                letter-spacing: 2px;
                overflow: hidden;
                transition: all 0.3s;
              }

              .right2 {
                width: 100%;
                word-wrap: break-word;
                padding-right: 30px;
                text-align: justify;
                line-height: 30px;
                letter-spacing: 2px;
                // overflow: hidden;
                transition: all 0.3s;
              }
              .fwb{
                white-space: pre-wrap;
                width: 100%;

                ::v-deep img{
                  width: 100%;
                }

              }


              .rightButton {
                margin-left: 20px;

                .clickButton {
                  margin: 0 10px;
                  border: 0;
                  background-color: #4e80f5;
                  color: #fff;
                  width: 50px;
                  height: 30px;
                  border-radius: 5px;
                  line-height: 30px;
                  cursor: pointer;
                }
              }
            }

            .right::before {
              content: "";
              width: 0;
              height: 100%;
              margin-bottom: -24px;
            }

            // .right1 {
            //   width: 75%;
            //   .fjtext {
            //     display: flex;
            //     align-items: center;
            //     margin: 10px 0;
            //     .txt {
            //       font-size: 14px;
            //     }
            //     .button {
            //       margin-left: 40px;
            //       width: 200px;
            //       .el-button {
            //         background: #4e80f5;
            //         padding: 5px 20px;
            //       }
            //     }
            //   }
            // }
          }
        }

        .buttonBottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          //margin: 0 0 25px 0;
          width: 90%;
          text-align: center;
          border-top: 1px solid #eaeaea;
          margin: 20px auto;

          .start {
            width: 20%;
            background-color: #4e80f5;
            color: #fff;
            //margin: 5px 0 15px 0;
            margin: 0 auto;
            margin-top: 15px;
          }

          .modify {
            width: 20%;
            background-color: #f2f2f2;
            color: #4e80f5;
            border: 0;
            margin: 0 auto;
            margin-top: 15px;

          }

          .el-button + .el-button {
            margin-left: 0;
          }
        }
      }
    }

    .right_box {
      margin: 70px auto;
      width: 34%;
      height: 100%;
      background-color: #eeeeee;
    }

    .right_wrap {
      //display: none;
      margin: 0 auto;
      width: 34%;
      background-color: #ffffff;
      border-radius: 10px;
      position: fixed;
      top: 80px;
      z-index: 2;

      .title {
        width: 100%;
        height: 40px;
        background-color: #4e80f5;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .title_left {
          float: left;
          margin-left: 15px;
          font-size: 16px;
          line-height: 40px;
          font-weight: bold;
          color: #ffffff;
        }

        .title_right {
          float: right;
          margin-right: 15px;
          font-size: 14px;
          line-height: 40px;
          color: #ffffff;
          cursor: pointer;
        }

      }

      .right_content {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .pfList {
          width: 96%;
          margin: 0 auto;


          .item_wrap {
            width: 96%;
            margin-left: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #eaeaea;


            .item_title {
              left: 0;
              font-size: 16px;
              font-weight: bold;
              line-height: 40px;
              color: #333333;
              text-align: left;
            }

            .item_message {
              margin-left: 0;
              font-size: 13px;
              line-height: 26px;
              color: #666666;
              text-align: left;
              white-space: pre-wrap;
            }

            .item_number_box {
              width: 100%;
              height: 33px;
              margin-top: 10px;

              .item_score {
                float: left;
                line-height: 33px;
                font-size: 14px;
                font-weight: bold;
                padding-right: 10px;
              }

              .item_input {
                float: left;
                height: 33px;
                max-width: 200px;
                min-width: 100px;
              }

              .item_min_max_scores {
                float: right;
                font-size: 14px;
                line-height: 33px;
                color: #4e80f5;
                padding: 0 10px;
              }
            }
          }
        }

        .tj_wrap {
          width: 100%;
          text-align: left;
          margin-left: 20px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;

          .tj_title {
            line-height: 40px;
            width: 15%;
          }
          .tj_message{
            width: 75%;
            margin-top: 10px;
          }

        }
      }


      .popBottom {
        width: 100%;
        border-top: 1px solid #cccccc;


        .submit {
          line-height: 30px;
          background-color: #ffffff;
          box-shadow: 0px 0px 10px #999999;
          font-size: 14px;
          padding: 5px 40px;
          border-radius: 6px;
          color: #4e80f5;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .save {
          line-height: 30px;
          background-color: #4e80f5;
          box-shadow: 0px 0px 10px #999999;
          font-size: 14px;
          padding: 5px 40px;
          border-radius: 6px;
          color: #ffffff;
          margin-top: 20px;
          margin-bottom: 20px;

        }
      }
    }
  }


  .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.5);
    position: absolute;
    top: 0;
    left: 0;

    .edit {
      position: absolute;
      top: 25px;
      right: 20px;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
    }

    .showModal {
      width: 45%;
      position: fixed;
      top: 10%;
      left: 29%;
      box-shadow: 0px 0px 20px #4e80f5;
      background-color: #fff;
      border-radius: 5px;

      .popTop {
        width: 100%;
        height: 70px;
        line-height: 70px;
        background-color: #4e80f5;
        text-align: center;
        color: #fff;
        border-radius: 5px 5px 0 0;
        font-size: 20px;
      }

      .popContent {
        // max-height: 500px;
        // overflow-y: scroll;
        padding: 0 10px;

        /deep/ .el-input__inner {
          &::placeholder {
            text-align: center;
          }

          width: 45%;
          border-radius: 25px;
          border-color: #4e80f5;
          color: #4e80f5;
          text-align: center;
        }
      }

      .popBottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0 20px 0;

        .save {
          background-color: #f2f2f2;
          border: 0;
          color: #4e80f5;
          width: 20%;
          border-radius: 20px;
        }

        .submit {
          background-color: #4e80f5;
          color: #fff;
          width: 20%;
          border-radius: 20px;
        }

        .el-button + .el-button {
          margin-left: 30px;
        }
      }
    }

    .pop {
      width: 45%;
      position: fixed;
      top: 20%;
      left: 29%;
      box-shadow: 0px 0px 20px #4e80f5;
      background-color: #fff;
      border-radius: 5px;
      padding-bottom: 20px;

      .img {
        width: 100%;
        padding-top: 60px;

        .image {
          width: 100px;
          height: 100px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .success {
        width: 100%;
        text-align: center;
        color: #4e80f5;
        font-size: 20px;
        margin: 10px 0 30px 0;
      }

      .totalScore {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 30px 0;

        span {
          font-size: 20px;
        }

        .totalNum {
          font-size: 30px;
          margin-top: -8px;
        }
      }

      .buttomPop {
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-button + .el-button {
          margin-left: 0;
        }

        .next {
          width: 70%;
          background-color: #4e80f5;
          color: #fff;
          border-radius: 25px;
          margin: 10px 0;
        }

        .back {
          width: 70%;
          background-color: #f2f2f2;
          color: #4e80f5;
          border-radius: 25px;
          margin: 10px 0;
          border: 0;
        }
      }
    }
  }

  /deep/ .rightImage {
    width: 115px;
  }
}

.picturePop {
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5);
  position: absolute;
  top: 0;
  left: 0;

  .pic {
    width: 45%;
    position: fixed;
    top: 10%;
    left: 30%;
    // box-shadow: 0px 0px 20px #4e80f5;
    background-color: transparent;
    border-radius: 5px;

    img {
      width: 90%;
    }
  }
}

.imgurl {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
}

.zhankai {
  cursor: pointer;
  color: #4e80f5;
  font-size: 14px;
  float: right;
}

.expande {
  height: auto;
}

.close {
  height: 65px;
  overflow: hidden;
}

.fileLeft {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.center-flex {
  display: flex;
  justify-content: center; /* 水平居中 */
  //align-items: center; /* 垂直居中 */
  //height: 200px; /* 设置div的高度 */
  //border: 1px solid #000; /* 边框仅为了可视化div的边界 */
}

.demo-image__preview {
  margin: 5px 0;
}

/deep/ .el-empty {
  padding: 60px 0;
}

/deep/ .el-input-number.is-controls-right .el-input__inner {
  padding: 0;
}

/deep/ .el-input-number {
  width: 216px !important;
}
</style>
