import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/initialization.css'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import API from '@/api/http'
import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad)

Vue.prototype.$api = API
Vue.prototype.axios = axios
// 测试地址
axios.defaults.baseURL = 'https://cmpmatch.digilinx.net.cn'
// 正式地址
// axios.defaults.baseURL = 'https://cqc-admin.yeeol.com'
//上传文件地址
Vue.prototype.$uploadUrl = 'https://cqc-admin.yeeol.com'

// 文件拼接地址
Vue.prototype.$showUrl = 'https://cmpmatch-files.oss-cn-beijing.aliyuncs.com'
Vue.config.productionTip = false
axios.interceptors.request.use(
  config => {
    if(config.url =="https://cqc-admin.yeeol.com/api/member/login"){
      return config;
    }else{
      if (sessionStorage.getItem('token')) { //判断token是否存在
        config.headers.token = sessionStorage.getItem('token');  //将token设置成请求头
      }
      return config;
    }
  },
  err => {
    return Promise.reject(err);
  }
);
// 响应拦截器
axios.interceptors.response.use(response => {
  // 对响应数据做些什么
  return response
}, error => {
  // 当响应异常时
    Vue.prototype.$message({
      message: '请求出错...',
      type: 'error',
      showClose: true
    })
  return Promise.reject(error)
})

router.beforeEach((to, from, next) => {
  // 如果访问login则放行next（）
  if (to.path === '/') {
    return next()
  }
  if(to.path == '/reset'){
    return next()
  }
  if(to.name == 'signName'){
    return next()
  }
  const token = sessionStorage.getItem('token')
  // 如果没有token 跳转到login，有 放行
  if (!token) {
    return next('/')
  }
  next()
})
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
