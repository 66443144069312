<template>
  <div class="container">
    <div v-if="viewPageSign == 1">
      <!-- 等待加载页面 -->
      <div class="loading-page">
        <p>加载中。。。。。</p>
      </div>
    </div>
    <div v-if="viewPageSign == 2">
      <!-- 签名页面 -->
      <div class="sign-page">
        <div class="signature-pad-box">
          <VueSignaturePad
              width="500px"
              height="200px"
              ref="signaturePad"
              class="signature-pad"
          ></VueSignaturePad>
        </div>
        <div class="signature-pad-button">
          <el-button type="text" @click="clear" >清除</el-button>
          <el-button type="success" @click="submit" >保存</el-button>
        </div>
        <div>
          <p>友情提示：横屏操作体验更好。</p>
        </div>
      </div>
    </div>
    <div v-if="viewPageSign == 3">
      <!-- 签名成功页面 -->
      <div class="success-page">
        <h1>操作成功！</h1>
        <p>您的签名已提交完成！</p>
        <p>如果您想要更改签名，请回到评分页面重新点击签字按钮！</p>
      </div>
    </div>
    <div v-if="viewPageSign == 4">
      <!-- 异常页面 -->
      <div class="error-page">
        <h1>出错了！</h1>
        <p>{{ errorMessage }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "signName",
  data() {
    return {
      signToken: "",
      base64Data: "",
      viewPageSign: 1,
      errorMessage: "页面异常，请重试！"
    }
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    submit() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty == true) {
        this.$message.error('请先完成签字哦！');
        return false;
      }
      this.base64Data = data;
      this.$confirm('此操作将提交签名文件, 是否继续?', '提示', {
        customClass: 'elmessageWidth',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.signToken);
        console.log(this.base64Data);
        // 将base64数据转换为Blob对象
        const blob = this.base64ToBlob(this.base64Data);
        console.log(this.blob)
        // 创建FormData对象并附加Blob对象
        let form = new FormData();
        form.append('token', this.signToken);
        form.append('signature', blob, 'signature.png');
        this.$api.saveSignature(form).then((res) => {
          if (res.status == 200) {
            this.$message.success("保存成功！");
            this.viewPageSign = 3;
          }
          this.recommend_score_window = false;
        }).catch((err) => {
          this.$message.error("保存失败，请检查网络");
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '您已取消保存操作！'
        });
      })

    },
    base64ToBlob(base64) {
      const arr = base64.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
  },
  mounted() {
    if (typeof this.$route.params.token === 'undefined' || this.$route.params.token == "") {
      this.viewPageSign = 4;
      this.errorMessage = "页面参数丢失，请重新尝试！";
      return false;
    }
    this.signToken = this.$route.params.token;
    //这里需要请求判断二维码是否已经签过字了
    let form = new FormData();
    form.append("token", this.signToken);
    this.$api.getSignature(form).then((res) => {
      if (res.data.code == 1 && res.data.data.signature_img) {
        this.viewPageSign = 3;
      } else {
        this.viewPageSign = 2;
      }
      console.log(res)
    }).catch((err) => {
      this.viewPageSign = 2;
    });

  },
  beforeDestroy() {
  }
}
</script>

<style scoped>

  .container {
    margin: 5px auto;
    width:500px;
    height: 100vh; /* 使容器的高度为视口高度，这样内容就可以居中显示 */
  }

  .signature-pad {
    margin: auto;
    border: 1px solid #000000; /* 设置边框颜色 */
    background-color: #fff; /* 设置背景色 */
  }
  .signature-pad-button {
    margin: 5px auto;
    text-align: center;
  }
  .error-page {
    text-align: center;
    margin-top: 100px;
  }
  .success-page {
    text-align: center;
    margin-top: 100px;
  }
  .loading-page {
    text-align: center;
    margin-top: 100px;
  }
</style>
<style>
  .elmessageWidth {
    width: 300px !important;
  }
</style>