import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    form: {
      avatar:'', //头像-string
      token: "",
      nickname: "", //昵称-string
      realname: "", //真实姓名-string
      cardid: "", //身份证号-string
      wx_account: "", //微信号-string
      birthday: "", //出生年月-string
      gender: "", //性别-int 0-保密，1-男，2-女
      education: "", //学历-int
      company: "", //单位名称-string
      position: "", //职位-string
      addressid: "", //联系地址-string
      address: "", //详细地址-string
      telephone: "", //联系电话-string
      email: "", //邮箱-string
      user_type: "", //评委类型-int 1-创业导师，2-投资人，3-专家学者，0-未选择
      // user_type==1显示
      experience: "", //从业经验-string
      demo: "", //主要业绩及案例-string
      honor: "", //获得社会荣誉-string
      description: "", //个人简介-string
      recommend: "", //推荐机构-string
      // user_type==2
      ide_category: "", //身份类别-string-多选 1,2,3
      invest_stage: "", //投资阶段-string
      invest_address: "", //投资地区-string
      invest_field: "", //投资关注领域-string
      invest_quota: "", //投资额度-string
      currency_type: "", //币种类型 id
      resources: "", //提供的资源
      business_name: "", //所在企业名称
      business_content: "", //企业地址
      business_address: "", //企业介绍
      invest_case: "", //案例-二维数组
      // invest_case[0][name]: '',//项目名称
      // invest_case[0][address]: '',//项目地址
      // invest_case[0][invest_time]: '',//投资时间
      // invest_case[0][invest_amount]: '',//投资金额
      // user_type == 3
      research: "", //研究领域
      experience_shcolar: "", //主要从业经历
      demo_shcolar: "", //主要著作及学术成功
      honor_shcolar: "", //社会荣誉
      description_shcolar: "", //个人简介
      recommend_shcolar: "", //推荐机构
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
