<template>
  <div class="login">
    <div class="content">
      <!-- <div class="logo">
        <img src="../assets/image/login.png" alt="" />
      </div> -->
      <div class="title">赛事评审系统</div>
      <div class="text" v-show="num != 3">
        <div class="textTitle">
          <span :class="num === 1 ? 'active' : 'noene'" class="loginpointer" @click="num = 1"
            >评委登录</span
          >
          <span :class="num === 2 ? 'active' : 'noene'" class="loginpointer" @click="num = 2"
            >注册账号</span
          >
        </div>
        <!-- 登录 -->
        <el-form
          v-show="num === 1"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item prop="account">
            <el-input
              v-model="ruleForm.account"
              placeholder="请输入登录手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="ruleForm.password"
              placeholder="请输入登录密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div class="code">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入验证码"
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
              <div class="login-code" @click="refreshCode">
                <!--验证码组件-->
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="pw">
              <el-checkbox v-model="checked">记住账号密码</el-checkbox>
              <span class="forget" @click="$router.push('/reset')"
                >忘记密码？</span
              >
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
          <div
            class="jszc"
            style="font-size: 13px; color: #99999; padding: 15px 0"
          >
            技术支持：13552946354
          </div>
        </el-form>
        <!-- 注册 -->
        <el-form
          class="forma"
          v-show="num === 2"
          :model="regForm"
          :rules="regrules"
          ref="regForm"
        >
          <el-form-item prop="nickname">
            <el-input
              v-model="regForm.nickname"
              placeholder="请输入昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              v-model="regForm.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              show-password
              v-model="regForm.password"
              placeholder="请设置登录密码，不少于8位数"
            ></el-input>
          </el-form-item>
          <el-form-item prop="oncepad">
            <el-input
              show-password
              v-model="regForm.oncepad"
              placeholder="请确认登录密码"
            ></el-input>
          </el-form-item>
          <el-form-item class="Verification" prop="verify">
            <el-input
              v-model="regForm.verify"
              placeholder="请输入手机验证码"
            ></el-input>
            <div class="captcha loginpointer" @click="getpushSms" v-show="show">
              获取验证码
            </div>
            <div class="captcha" v-show="!show">{{ count }}秒</div>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="checked2" @change="checks"
              >注册即同意《平台用户服务协议》</el-checkbox
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getregister">立即注册</el-button>
          </el-form-item>
          <div
            class="jszc"
            style="font-size: 13px; color: #99999; padding: 15px 0"
          >
            技术支持：13552946354
          </div>
        </el-form>
      </div>
      <div class="text2" v-show="num === 3">
        <div class="protocol">平台用户服务协议</div>
        <div class="min">
          <div>
            <p>尊敬的用户：</p>
            <p>
              欢迎阅读创本台服务协议（下称“本协议”），您应当在使用本网站以及获取相关服务之前认真阅读本协议全部内容。
              本协议内容包括协议正文、附件及所有相关赛事已经发布的或将来可能发布的各类规则。所有规则为协议不可分割的一部分，
              与协议正文具有同等法律效力。本协议阐述之条款和条件适用于用户使用本网站所提供的各种在线工具、服务和衍生的线下服务。
              如果您对本协议条款表示异议（以下如无特殊说明，
              “本协议条款”均包括本协议条款随时所做的修改），您可以选择不使用本网站的服务。注册成为本平台的用户，则意味着您对本协议条款无任何异议并自愿遵守本协议。
            </p>
            <p>一、定义</p>
            <p>
              1.
              本平台：中国青年创业就业基金会打造全球专业的创新创业综合服务系统，平台以线上系统为服务载体，整
              合国内外优质资源，采用大数据、智慧智能等高新技术理念和互联互享的先进产业模式，从而搭建起政府、企业、创投、企
              服为一体的比赛生态链。创青春网站的运营权归中国青年创业就业基金会所有，两者视为一个主体。
            </p>
            <p>
              2.
              用户：是同意遵守本协议，在本网站完成注册，进行创建项目、报名活动、发布信息等享受平台服务的主体，包括项目方、
              资金方、信息的发布者等所有参与本平台活动的自然人、法人或者其他组织。用户应当具备相应民事行为能力，不具备民事行为能力或者缺乏特定许可资质的用户与本网站签署的协议无效
            </p>
          </div>
          <div style="text-align:center">
            <el-button type="primary" @click="num=2"
              >我已阅读,并同意本协议</el-button
            >
          </div>
        </div>
      </div>
      <div class="zhe" v-show="num === 3"></div>
    </div>
  </div>
</template>

<script>
import SIdentify from "../components/sidentify";
export default {
  components: { SIdentify },
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.identifyCode) {
        callback(new Error("验证码输入错误"));
      } else {
        callback();
      }
    };
    var checkAge = (rule, value, callback) => {
      if (this.$data.regForm.password !== value) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    }
    var checkpsd = (rule, value, callback) => {
      if (value.length < 8) {
        callback(new Error("密码长度不能小于8"));
      } else {
        callback();
      }
    }
    return {
      checked: false,
      checked2: false,
      num: 1,
      identifyCodes: "abcdefghigklmnopquvwsyz1234567890", //验证码组合来源
      identifyCode: "", //验证码
      ruleForm: {
        account: "",
        password: "",
        code: "",
      },
      // 注册
      regForm: {
        nickname: "",
        phone: "",
        password: "",
        oncepad: "",
        user_type: "",
        verify: "",
        email: "",
        type: "2"
      },
      regrules: {
        nickname: [
          { required: true, message: "请输入账号昵称", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkpsd, trigger: 'blur' }
        ],
        oncepad: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: checkAge, trigger: 'blur' }
        ],
        verify: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ]
      },
      rules: {
        account: [
          { required: true, message: "请输入专家评委账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      show: true,
      count: '',
      timer: null,
    };
  },
  mounted () {
  },
  created () {
    this.refreshCode();
    let a = localStorage.getItem('judgePhone')
    if (a == null) {
      this.checked = false
      return
    }
    this.checked = true;
    this.ruleForm.account = a
  },
  methods: {
    // 表单验证
    submitForm (formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.getlogin();
        } else {
          that.refreshCode();
        }
      });
    },
    getlogin () {
      let formData = new FormData();
      formData.append("account", this.ruleForm.account);
      formData.append("password", this.ruleForm.password);
      formData.append("type", 2);
      this.$api
        .login(formData)
        .then(({ data: { code, msg, data } }) => {
          if (code === 1) {
            this.$message.success(msg);
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("group", data.group);
            if (this.checked) {
              localStorage.setItem('judgePhone', this.ruleForm.account)
            }
            this.$router.push("/index/list?once="+1);
          } else {
            this.$message.error(msg);
          }
        })
        .catch((err) => {
          this.$message.error("网络出错");
        });
    },

    checks () {
      console.log(this.checked2);
      if (this.checked2 === true) {
        this.num = 3
      }
    },

    // 注册
    getregister () {
       this.$refs.regForm.validate((valid) =>{
         if(valid) {
           this.goregsiter()
         }
       })
      
    },
    goregsiter(){
      let form = new FormData()
      form.append('nickname', this.regForm.nickname)
      form.append('phone', this.regForm.phone)
      form.append('password', this.regForm.password)
      form.append('verify', this.regForm.verify)
      form.append('user_type', '2')
      form.append('type', this.regForm.type)
      this.$api.register(form).then(res => {
       
        if (res.data.code === 1) {
          this.$message.success(res.data.msg)
          this.ruleForm.account = this.regForm.phone
          this.num = 1
        } {
          this.$message.error(res.data.msg)
        }
      }).catch(err => { })
    },

    //验证码
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode () {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 手机验证码
    getpushSms () {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer)
            this.timer = null;
          }
        }, 1000)
      }
      var form = new FormData()
      form.append('mobile', this.regForm.phone)
      this.$api.pushSms(form).then(res => {
        this.$message.success('验证码已发送')
      })
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1030px) {
  .content {
    width: 82% !important;
    left: 10% !important;
  }
}
@media screen and (max-width: 1000px) {
  .content {
    width: 100% !important;
    left: 0% !important;
  }
}
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/back.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .content {
    width: 50%;
    position: relative;
    left: 25%;
    top: 18%;
    text-align: center;
    .logo {
      width: 20%;
      height: 20%;
      background: yellow;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 4rem;
      font-weight: 600;
      margin-bottom: 30px;
      color: #fff;
    }
    .text {
      width: 65%;
      padding-bottom: 20px;
      // padding-top: 40px;
      background: #fff;
      margin: 0 auto;
      border-radius: 10px;
      .textTitle {
        font-size: 20px;
        padding: 30px 0;
        letter-spacing: 6px;
      }
      .code {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .pw {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .forget {
          font-size: 12px;
          cursor: pointer;
        }
      }
      .el-form-item {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 22px;
      }
      .jszc {
        font-size: 13px;
        color: #999999;
        padding: 15px 0;
      }
    }
    .zhe {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #72727277;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .text2 {
      position: relative;
      .min {
        padding: 0 20px 20px;
      }
      z-index: 99;
      border-radius: 10px;
      box-shadow: 0 0 10px #bfc0c2;
      width: 70%;
      // padding-top: 40px;
      background: #fff;
      margin: 0 auto;
      border-radius: 10px;
      text-align: left;
      .protocol {
        background: #4e80f5;
        text-align: center;
        color: #fff;
        font-size: 26px;
        padding: 20px 0;
      }
    }
  }
}
.noene {
  color: rgb(190, 190, 190);
  margin: 0 10px;
}
.active {
  position: relative;
  margin: 0 10px;
  color: #000;
}
.active::before {
  width: 70px;
  height: 3px;
  background: #4e80f5;
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.el-input__inner {
  background-color: #eef3fe;
  border: 0;
  border-radius: 25px;
  color: #999999;
}
/deep/.el-checkbox__label {
  font-size: 12px;
  color: #4e80f5;
  padding-left: 5px;
}
.forma {
  /deep/.el-form-item__content {
    display: flex;
  }
}
.Verification {
  /deep/.el-input__inner {
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.captcha {
  width: 100px;
  background: #4e80f5;
  padding: 0 20px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
}
.el-button {
  width: 93%;
  border-radius: 25px;
  background: #4e80f5;
}
.loginpointer{
  cursor: pointer;
}
</style>