<template>
  <div class="word">
    <div class="title">完善信息</div>
    <div>
      <el-form label-width="150px" :rules="rules" :model="form" ref="ruleForm">
        <div class="" style="width: 90%">
          <el-form-item label="头像：">
            <div style="display: flex">
              <!-- <span class="picture">头像：</span> -->
              <el-upload
                class="avatar-uploader"
                :action="$uploadUrl + '/api/upload/push'"
                :show-file-list="false"
                :auto-upload="false"
                :before-upload="beforeAvatarUpload"
                :on-change="fileChange"
                :headers="{ 'Content-Type': 'multipart/form-data' }"
              >
                <img
                  v-if="form.avatar"
                  :src="$showUrl + form.avatar"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="details">
                <span>支持JPG、JPEG、PNG图片格式</span>
                <span>建议尺寸：200*200像素</span>
                <span>大小：不超过2M</span>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="用户昵称：" prop="nickname" required>
            <el-input v-model="form.nickname" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="真实姓名：" prop="realname" required>
            <el-input
              v-model="form.realname"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="微信号：" required>
            <el-input v-model="form.wx_account" placeholder="请输入"></el-input>
          </el-form-item> -->
          <el-form-item label="身份证号：" prop="cardid" required>
            <el-input
              v-model="form.cardid"
              placeholder="请输入有效身份证号"
              maxlength="18"
            ></el-input>
            <div style="color: #999999">只用作平台信息认证，不对外泄漏</div>
          </el-form-item>
          <el-form-item label="性别：" prop="gender" required>
            <el-radio-group v-model="form.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
              <el-radio :label="0">保密</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="学历：" prop="education" required>
            <el-select
              v-model="form.education"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in educationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在单位名称：" prop="company" required>
            <el-input v-model="form.company" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item
            label="所在单位简介："
            prop="company_description"
            required
          >
            <el-input
              type="textarea"
              v-model="form.company_description"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="职务：" prop="position" required>
            <el-input v-model="form.position" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div style="width: 95%">
          <el-form-item label="联系地址：" required>
            <el-select
              v-model="sheng"
              placeholder="请选择"
              style="width: 28%"
              @change="getremain"
            >
              <el-option
                v-for="item in remainList"
                :key="item.id"
                :label="item.prov_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="margin: 0 10px">-</span>
            <el-select
              v-model="shi"
              placeholder="请选择"
              style="width: 28%"
              @change="getcity"
            >
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.city_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="margin: 0 10px">-</span>
            <el-select v-model="xian" placeholder="请选择" style="width: 28%">
              <el-option
                v-for="item in county"
                :key="item.county_code"
                :label="item.county_name"
                :value="item.county_code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="width: 95%">
            <el-form-item>
              <el-input
                v-model="form.address"
                placeholder="请输入详细地址"
                style="margin-top: 10px"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div style="width: 90%">
          <el-form-item label="联系电话：" prop="telephone" required>
            <el-input
              v-model="form.telephone"
              maxlength="11"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱：" prop="email" required>
            <el-input v-model="form.email" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="评委类型：" prop="user_type" required>
            <el-select
              v-model="form.user_type"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in judType"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- 创业导师填写 -->
        <div style="width: 90%" v-if="form.user_type === 1">
          <el-form-item label="主要从业经历：" prop="experience" required>
            <el-input
              type="textarea"
              v-model="form.experience"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="主要业绩及案例：" prop="demo" required>
            <el-input
              type="textarea"
              v-model="form.demo"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="获得社会荣誉：" prop="honor" required>
            <el-input
              type="textarea"
              v-model="form.honor"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="个人简介：" required>
            <el-input
              type="textarea"
              v-model="form.description"
              placeholder="请输入"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="推荐机构：" >
            <el-input v-model="form.recommend" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <!-- 投资人填写 -->
        <div style="width: 90%" v-if="form.user_type === 2">
          <el-form-item label="身份类别："  class="is-required">
            <div @click="sflbPop = !sflbPop">
              <el-input
                placeholder="请选择身份类别"
                suffix-icon="el-icon-caret-bottom"
                v-model="checkList"
                disabled
              >
              </el-input>
            </div>
            <div class="pop" v-show="sflbPop">
              <!-- <div class="popTitle">最多可选3个</div> -->
              <div>
                <el-checkbox-group v-model="ide_category2">
                  <el-checkbox
                    v-for="check in checkList_sflb"
                    :key="check.id"
                    :label="check.id"
                  >
                    {{ check.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="投资阶段：" class="is-required">
            <div @click="tzjdPop = !tzjdPop">
              <el-input
                placeholder="请选择投资阶段"
                suffix-icon="el-icon-caret-bottom"
                v-model="checkStage"
                disabled
              >
              </el-input>
            </div>
            <div class="pop" v-show="tzjdPop">
              <!-- <div class="popTitle">最多可选3个</div> -->
              <div>
                <el-checkbox-group v-model="invest_stage2">
                  <el-checkbox
                    v-for="tz in checkList_tzjd"
                    :key="tz.id"
                    :label="tz.id"
                    >{{ tz.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="投资意向地区：" required>
            <div @click="tzyxdqPop = !tzyxdqPop">
              <el-input
                v-model="investment"
                placeholder="请选择投资意向地区"
                suffix-icon="el-icon-caret-bottom"
                disabled
              ></el-input>
            </div>
            <div class="xiala" v-show="tzyxdqPop">
              <p>最多可选10个城市</p>
              <div class="box">
                <div
                  class="box-cel"
                  v-for="pr in provList"
                  :key="pr.id"
                  :class="{ 'box-decide': boxList.indexOf(pr) !== -1 }"
                  @click="clickbox(pr)"
                >
                  <span>{{ pr.name }}</span>
                </div>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item label="投资关注领域：" class="is-required">
            <div @click="tzgzlyPop = !tzgzlyPop">
              <el-input
                v-model="checkact_text"
                placeholder="请选择投资关注领域"
                suffix-icon="el-icon-caret-bottom"
                disabled
              ></el-input>
            </div>
            <div class="xiala" v-show="tzgzlyPop">
              <!-- <p>最多可选5个</p> -->
              <div class="box">
                <div
                  class="box-cel"
                  v-for="pr in checkList_tzly"
                  :key="pr.id"
                  :class="{ 'box-decide': checkact.indexOf(pr) !== -1 }"
                  @click="clickboxact(pr)"
                >
                  <span>{{ pr.name }}</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="投资币种：" required>
            <el-select v-model="form.currency_type" placeholder="请选择">
              <el-option
                v-for="item in amountTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="单笔投资额度：" required>
            <div @click="dbtzedPop = !dbtzedPop">
              <el-input
                v-model="clickboxquota_text"
                placeholder="请选择单笔投资额度，最多可选5个"
                suffix-icon="el-icon-caret-bottom"
                disabled
              ></el-input>
            </div>
            <div class="xiala" v-show="dbtzedPop">
              <p>最多可选3个项目</p>
              <div class="box">
                <div
                  class="box-cel"
                  v-for="pr in checkList_tzed"
                  :key="pr.id"
                  :class="{ 'box-decide': checkcquota.indexOf(pr) != -1 }"
                  @click="clickboxquota(pr)"
                >
                  <span>{{ pr.name }}</span>
                </div>
              </div>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="投资案例：" required>
            <div>
              <div v-for="caser in invest_case" :key="caser.name">
                <span>{{caser.name}}</span> -- <span>{{caser.address}}</span> -- <span>{{caser.invest_time}}</span> -- <span>{{caser.invest_amount}}</span>
              </div>
            </div>
            <div class="add" @click="caseAdd">+添加</div>
            <div
              class="pop"
              v-for="(item, index) in caseList"
              :key="index"
              @mouseenter="seen = index"
              @mouseleave="seen = -1"
            >
              <div
                class="deleteIcon"
                @click="deleteList(index)"
                v-show="seen == index"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div style="width: 100%; margin: 0 auto">
                <el-form-item
                  label="项目名称"
                  required
                  style="margin-bottom: 15px; margin-top: 30px"
                >
                  <el-input
                    v-model="caseList[index].name"
                    placeholder="请输入项目名称"
                    style="width: 78%"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="项目地址"
                  required
                  style="margin-bottom: 15px"
                >
                  <el-input
                    v-model="caseList[index].address"
                    placeholder="请输入项目地址"
                    style="width: 78%"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="投资时间"
                  required
                  style="margin-bottom: 15px"
                >
                  <el-input
                    v-model="caseList[index].invest_time"
                    placeholder="请输入投资时间"
                    style="width: 78%"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="投资金额"
                  required
                  style="margin-bottom: 15px"
                >
                  <el-input
                    v-model="caseList[index].invest_amount"
                    placeholder="请输入投资金额"
                    style="width: 78%"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item label="投资案例：" prop="project_demo" required>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="form.project_demo"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="可提供资源：" prop="resources" required>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.resources"
            >
            </el-input>
          </el-form-item>
          <!-- <div class="tzr">企业/机构信息</div>
          <el-form-item label="所在机构/企业名称:" required>
            <el-input
              v-model="form.business_name"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业地址:" required>
            <el-input
              v-model="form.business_address"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业地址:" required>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.business_content"
            >
            </el-input>
          </el-form-item> -->

          <el-form-item label="主要从业经历：" prop="experience" required>
            <el-input
              type="textarea"
              v-model="form.experience"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="获得社会荣誉：" prop="honor" required>
            <el-input
              type="textarea"
              v-model="form.honor"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="推荐机构：" >
            <el-input v-model="form.recommend" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <!-- 专家学者填写 -->
        <div style="width: 90%" v-if="form.user_type === 3">
          <el-form-item label="研究领域：" prop="research" required>
            <el-input
              type="textarea"
              v-model="form.research"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="主要从业经历："
            prop="experience_shcolar"
            required
          >
            <el-input
              type="textarea"
              v-model="form.experience_shcolar"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="主要著作及学术成就："
            prop="demo_shcolar"
            required
          >
            <el-input
              type="textarea"
              v-model="form.demo_shcolar"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="获得社会荣誉：" prop="honor_shcolar" required>
            <el-input
              type="textarea"
              v-model="form.honor_shcolar"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="个人简介：" required>
            <el-input
              v-model="form.description_shcolar"
              placeholder="请输入"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="推荐机构：" >
            <el-input
              v-model="form.recommend_shcolar"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="专项赛评审意向" required>
            <el-checkbox-group v-model="intention">
              <el-checkbox
                v-for="item in checkboxxx"
                :label="item.id"
                :key="item.id"
                >{{ item.lab }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div>
      <el-button class="btn" @click="commit">申请认证</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!(/^1(3|4|5|6|7|8|9)+\d{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号'));
      } else {
        return callback();
      }
    }
    var checkcard = (rule, value, callback) => {
      if (value.length !== 18) {
        callback(new Error('请输入正确的身份证号码'));
      } else {
        return callback();
      }
    }
    return {
      seen: "",
      imageUrl: "",
      token: "",
      sheng: "", //省下拉框选中
      shi: "", //市下拉框选中该
      xian: "", //县下拉框选中
      checkList: "", //身份类别-页面显示
      checkStage: "", //投资阶段-页面显示
      investment: "", //投资意向地区-页面显示
      checkact_text: "", //投资关注领域-页面显示
      clickboxquota_text: "", //投资额度页面显示
      checkboxxx: [
        { id: '1', lab: "科技创新专项赛" },
        { id: '2', lab: "乡村振兴专项赛" },
        { id: '3', lab: "数字经济专项赛" },
        { id: '4', lab: "社会企业专项赛" }
      ],
      intention: [],
      form: {
        avatar: "", //头像
        token: "", //头像-string
        nickname: "", //昵称-string
        realname: "", //真实姓名-string
        cardid: "", //身份证号-string
        wx_account: "", //微信号-string
        birthday: "", //出生年月-string
        gender: "", //性别-int 0-保密，1-男，2-女
        education: "", //学历-int
        company: "", //单位名称-string
        company_description: "",//单位简介
        position: "", //职位-string
        addressid: "", //联系地址-string
        address: "", //详细地址-string
        telephone: "", //联系电话-string
        email: "", //邮箱-string
        user_type: "", //评委类型-int 1-创业导师，2-投资人，3-专家学者，0-未选择
        // user_type==1显示
        experience: "", //从业经验-string
        demo: "", //主要业绩及案例-string
        honor: "", //获得社会荣誉-string
        // description: "", //个人简介-string
        recommend: "", //推荐机构-string
        // user_type==2
        ide_category: "", //身份类别-string-多选 1,2,3
        invest_stage: "", //投资阶段-string
        // invest_address: "", //投资地区-string
        invest_field: "", //投资关注领域-string
        // invest_quota: "", //投资额度-string
        // currency_type: "", //币种类型 id
        project_demo: "",//投资案例
        resources: "", //提供的资源
        // business_name: "", //所在企业名称
        // business_content: "", //企业地址
        // business_address: "", //企业介绍
        // user_type == 3
        research: "", //研究领域
        experience_shcolar: "", //主要从业经历
        demo_shcolar: "", //主要著作及学术成功
        honor_shcolar: "", //社会荣誉
        description_shcolar: "", //个人简介
        recommend_shcolar: "", //推荐机构
      },
      rules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ],
        realname: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        cardid: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            validator: checkcard,
            trigger: 'blur'
          }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'blur' },
        ],
        education: [
          { required: true, message: '请选择学历', trigger: 'blur' },
        ],
        company: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
        ],
        company_description: [
          { required: true, message: '请输入单位简介', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        addressid: [
          { required: true, message: '请选择联系地址', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        telephone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
        ],
        user_type: [
          { required: true, message: '请选择评委类型', trigger: 'blur' },
        ],
        experience: [
          { required: true, message: '请输入从业经历', trigger: 'blur' },
        ],
        demo: [
          { required: true, message: '请输入业绩及案例', trigger: 'blur' },
        ],
        
        honor: [
          { required: true, message: '请输入获得的社会荣誉', trigger: 'blur' },
        ],
        project_demo: [
          { required: true, message: '请输入投资案例', trigger: 'blur' },
        ],
        resources: [
          { required: true, message: '请输入可提供的资源', trigger: 'blur' },
        ],
        research: [
          { required: true, message: '请输入研究领域', trigger: 'blur' },
        ],
        experience_shcolar: [
          { required: true, message: '请输入主要从业经历', trigger: 'blur' },
        ],
        demo_shcolar: [
          { required: true, message: '请输入主要著作及学术成功', trigger: 'blur' },
        ],
        honor_shcolar: [
          { required: true, message: '请输入获得的社会荣誉', trigger: 'blur' },
        ],
        description_shcolar: [
          { required: true, message: '请输入个人简介', trigger: 'blur' },
        ],
      },//校验表单
      checkList_sflb: [], //身份类别列表
      checkList_tzed: [], //投资额度
      checkList_tzjd: [], //投资阶段列表
      checkList_tzly: [], //投资领域列表
      provList: [], // 投资意向地区列表
      remainList: [], //省级地址表
      cityList: [], //市级地址表
      county: [], //区县地址表
      educationList: [
        { id: 1, name: "专科" },
        { id: 2, name: "本科" },
        { id: 3, name: "硕士" },
        { id: 4, name: "博士" },
        { id: 5, name: "博士后" },
        { id: 6, name: "在校大学生" },
        { id: 7, name: "其他" },
      ], //学历列表
      judType: [
        { code: 1, value: "创业导师" },
        { code: 2, value: "投资人" },
        { code: 3, value: "专家学者" },
      ], //评委类型列表
      ide_category2: [], //身份类别 checkbox选中列表，watch监测向表单内写入
      invest_stage2: [], //投资阶段，checkbox选列表，watch监测向表单内写入
      boxList: [], //投资意向地区选中列表，watch监测向表单内写入
      checkact: [], //投资关注领域选中列表，watch监测向表单内写入
      checkcquota: [], //投资案例
      amountTypeList: [], //投资币种
      casedata: {
        name: "", //项目名称
        address: "", //项目地址
        invest_time: "", //投资时间
        invest_amount: "", //投资金额
      },
      invest_case: [],
      sflbPop: false,
      tzjdPop: false,
      tzyxdqPop: false,
      tzgzlyPop: false,
      dbtzedPop: false,
      caseList: [
        {
          i: "",
          name: "", //项目名称
          address: "", //项目地址
          invest_time: "", //投资时间
          invest_amount: "", //投资金额
        },
      ],
    };
  },
  watch: {
    // 身份类别数据修改-页面显示
    ide_category2 (val) {
      // 身份类别不可超过3个
      // if (val.length > 3) {
      //   this.ide_category2.pop();
      //   return;
      // }
      let text = [];
      for (let i in val) {
        let a = this.checkList_sflb.filter((it) => {
          return it.id === val[i];
        });
        if (a.length > 0) text.push(a[0].name);
      }
      // 页面显示的内容
      this.checkList = text.join(",");
      // 保单提交的内容
      this.form.ide_category = val.join(",");
    },
    // 投资阶段数据修改-页面显示
    invest_stage2 (val) {
      // 身份类别不可超过3个
      // if (val.length > 3) {
      //   this.invest_stage2.pop();
      //   return;
      // }
      let text = [];
      for (let i in val) {
        let a = this.checkList_tzjd.filter((it) => {
          return it.id === val[i];
        });
        if (a.length > 0) text.push(a[0].name);
      }
      // 页面显示的内容
      this.checkStage = text.join(",");
      // 保单提交的内容
      this.form.invest_stage = val.join(",");
    },
    // 投资意向地区修改-页面显示-提交表单
    boxList (val) {
      let text = [];
      let idList = [];
      for (let i in val) {
        text.push(val[i].name);
        idList.push(val[i].id);
      }
      this.investment = text.join();
      this.form.invest_address = idList.join();
    },
    // 投资领域  - 页面显示-提交表单
    checkact (val) {
      let text = [];
      let idList = [];
      if (val.length > 0) {
        for (let i in val) {
          if (val[i]) {
            text.push(val[i].name);
            idList.push(val[i].id);
          }
        }
        this.checkact_text = text.join();
        this.form.invest_field = idList.join();
      }
    },
    // 投资额度 - 页面显示-提交表单
    checkcquota (val) {
      let text = [];
      let idList = [];
      if (val.length > 0) {
        for (let i in val) {
          text.push(val[i].name);
          idList.push(val[i].id);
        }
        this.clickboxquota_text = text.join();
        this.form.invest_quota = idList.join();
      }
    },
  },
  created () {
    this.judInfo()


    this.token = sessionStorage.getItem("token");
  },
  methods: {
    // 上传文件
    fileChange (file) {
      this.imageUrl = file.raw;
      let formData = new FormData();
      formData.append("imgfile", this.imageUrl);
      formData.append("token", this.token);
      this.$api.upload(formData).then((res) => {
        this.form.avatar = res.data.data.url;
      });
      // formData.append('file', this.file);
    },
    // 评委信息提交审核--申请认证按钮
    commit () {
      this.form.addressid = `${this.sheng},${this.shi},${this.xian}`;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.token = sessionStorage.getItem("token");
          var info = new FormData();
          for (let i in this.form) {
            info.append(i, this.form[i]);
          }
          info.append('intention', this.intention.join())
          // 投资案例
          if (this.caseList.length > 0) {
            for (let j in this.caseList) {
              info.append(`invest_case[${j}][name]`, this.caseList[j].name);
              info.append(`invest_case[${j}][address]`, this.caseList[j].address);
              info.append(
                `invest_case[${j}][invest_time]`,
                this.caseList[j].invest_time
              );
              info.append(
                `invest_case[${j}][invest_amount]`,
                this.caseList[j].invest_amount
              );
            }
          }
          this.$api
            .setComment(info)
            .then(({ data: { code, msg } }) => {
              if (code === 1) {
                this.$message.success("申请成功");
                this.$emit('updataimg')
                this.$emit("formrenovate");
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              this.$message.error("网络出错");
            });
        }

      })

    },
    // 投资案例添加
    caseAdd () {
      this.caseList.unshift(this.casedata);
    },
    // 投资案例删除
    deleteList (index) {
      this.caseList.splice(index, 1);
    },
    // 投资意向地区点击事件,watch监测数据变化向表单、页面input框写入
    clickbox (val) {
      let num = this.boxList.indexOf(val);
      if (num === -1) {
        if (this.boxList.length >= 10) return;
        this.boxList.push(val);
      } else {
        this.boxList.splice(num, 1);
      }
    },
    // 投资关注领域点击事件,watch监测数据变化向表单、页面input框写入
    clickboxact (val) {
      let num = this.checkact.indexOf(val);
      if (num === -1) {
        // if (this.checkact.length >= 5) return;
        this.checkact.push(val);
      } else {
        this.checkact.splice(num, 1);
      }
    },
    // 投资额度点击事件,watch监测数据变化向表单、页面input框写入
    clickboxquota (val) {
      let num = this.checkcquota.indexOf(val);
      if (num === -1) {
        if (this.checkcquota.length >= 3) return;
        this.checkcquota.push(val);
      } else {
        this.checkcquota.splice(num, 1);
      }
    },
    // 图片上传成功
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    //图片上传校验
    beforeAvatarUpload (file) {
      const isJPG = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG && !isJpeg) {
        this.$message.error("上传头像支持jpg、jpeg、png图片格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG || isJpeg) && isLt2M;
    },
    // 获取地区数据 -- 投资意向地区回显
    async getprov () {
      let res = await this.$api.prov();
      this.provList = res.data.data;
      // 投资意向地区显示
      if (this.form.invest_address) {
        for (let i in this.form.invest_address) {
          let data = this.provList.filter((it) => {
            return this.form.invest_address[i] == it.id;
          });
          this.boxList.push(data[0]);
        }
      }
    },
    // 获取投资领域
    getindustry () {
      this.$api.industry().then((res) => {
        this.checkList_tzly = res.data.data;
        if (this.form.invest_field) {
          for (let i in this.form.invest_field) {
            let data = this.checkList_tzly.filter((it) => {
              return this.form.invest_field[i] == it.id;
            });
            this.checkact.push(data[0]);
          }
        }
      });
    },
    // 获取投资额度列表
    getstment () {
      this.$api.stment().then((res) => {
        this.checkList_tzed = res.data.data;
        if (this.form.invest_quota) {
          for (let i in this.form.invest_quota) {
            let data = this.checkList_tzed.filter((it) => {
              return this.form.invest_quota[i] == it.id;
            });
            this.checkcquota.push(data[0]);
          }
        }
      });
    },
    // 获取学历列表
    getEducation () {
      this.$api.education().then((res) => {
        this.educationList = res.data.data;
      });
    },
    // 投资身份列表
    getident () {
      this.$api.ident().then((res) => {
        this.checkList_sflb = res.data.data;
        if (this.form.ide_category) {
          this.form.ide_category.forEach((it) => {
            this.ide_category2.push(Number(it));
          });
        }

      });
    },
    // 获取投资阶段列表
    getphase () {
      this.$api.phase().then((res) => {
        this.checkList_tzjd = res.data.data;
        if (this.form.invest_stage) {
          this.form.invest_stage.forEach((it) => {
            this.invest_stage2.push(Number(it));
          });
        }
      });
    },
    // 投资币种列表
    getamountType () {
      this.$api.amountType().then((res) => {
        this.amountTypeList = res.data.data;
      });
    },
    // 获取地址列表
    getAddress () {
      this.$api.address().then((res) => {
        this.remainList = res.data.data;
        if (this.form.addressid) {
          let address = this.form.addressid.split(",");
          this.sheng = Number(address[0]);
          let cityList = this.remainList.filter((it) => {
            return it.id === this.sheng;
          });
          if (cityList[0].content) {
            this.cityList = cityList[0].content;
            this.shi = Number(address[1]);
            let county = this.cityList.filter((it) => {
              return it.id === this.shi;
            });
            this.county = county[0].content;
            this.xian = Number(address[2]);
          }
        }
      });
    },
    // 点击省级列表，获取市级列表
    getremain (e) {
      let cityList = this.remainList.filter((it) => {
        return it.id === e;
      });
      this.cityList = cityList[0].content;
    },
    // 点击市级列表，获取县区级列表
    getcity (e) {
      let county = this.cityList.filter((it) => {
        return it.id === e;
      });
      this.county = county[0].content;
    },


    // 个人信息
    judInfo () {
      let data2 = {
        token: sessionStorage.getItem("token"),
      };
      this.$api.getinfo({ params: data2 }).then(({ data: { data } }) => {
        this.form = data;
        if (data.user_type === 2) {
          this.form.experience = data.invest_experience
          this.form.honor = data.invest_honor
          this.form.recommend = data.invest_recommend
        }
        // this.getamountType(); //投资币种列表
        // 后台获取数据为表单赋值--回显
        if (data.intention) {
          this.intention = data.intention.split(',')
        }
        this.caseList = data.invest_case;
        this.getprov(); //地区列表
        this.getEducation(); //学历列表
        this.getident(); //身份列表
        this.getAddress(); //地址列表
        this.getphase(); //投资阶段列表
        this.getindustry(); //投资领域列表
        this.getstment(); //投资额度列表
      });
    },
  },
};
</script>
<style lang='less' scoped>
.center {
  .title {
    color: #000;
    padding-left: 20px;
    font-size: 14px;
    margin: 0 0 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  .el-dropdown {
    width: 30%;
  }
  .btn {
    background: #4e80f5;
    color: #fff;
    margin-left: 170px;
  }
}
.flex {
  display: flex;
  padding: 20px 20px;
  .left {
    width: 100px;
  }
}
.message {
  display: flex;
  .left {
    width: 60%;
  }
  .right {
    margin-left: 35px;
    .rightPicture {
      display: flex;
      .picture {
        font-size: 14px;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #ff6600;
      margin-left: 40px;
      margin-top: 10px;
    }
  }
}
/deep/.el-upload {
  border: 1px solid #eee;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}
.avatar {
  width: 130px;
  height: 130px;
  display: block;
}
.xiala {
  border: 1px solid #eee;
  background: #fafafa;
  padding: 0 20px 20px;
  .box {
    display: flex;
    flex-wrap: wrap;
    .box-cel {
      border: 1px solid transparent;
      background: #e4e6e7;
      padding: 0px 14px;
      position: relative;
      line-height: 30px;
      margin: 5px 10px;
      cursor: pointer;
    }
    .box-decide {
      border: 1px solid rgb(49, 143, 138);
    }
    .box-decide:after {
      content: "√";
      position: absolute;
      width: 13px;
      height: 13px;
      right: -2px;
      bottom: 0;
      line-height: 13px;
      color: #fff;
    }
    .box-decide:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: -10px;
      bottom: -2px;
      border-bottom: 15px solid rgb(49, 143, 138);
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      transform: rotate(135deg);
    }
  }
}
.tzr {
  color: #000;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
  margin-left: 55px;
  padding-left: 20px;
  margin-bottom: 30px;
}
.pop {
  padding: 10px 10px 10px 30px;
  background-color: #fafafa;
  margin: 10px 0;
  .deleteIcon {
    text-align: right;
    margin-right: 10px;
    height: 0;
  }
  .popList {
    margin: 0 auto;
  }
  .popTitle {
    color: #9a9999;
    font-size: 14px;
  }
  .save {
    text-align: center;
    font-size: 14px;
    color: #409692;
  }
  .el-checkbox {
    margin-right: 65px;
  }
}
.add {
  color: #308e8d;
  cursor: pointer;
}
.word {
  .el-select {
    width: 100%;
  }
}
.rightPicture {
  display: flex;
  padding-left: 111px;
  margin-bottom: 22px;
}
.picture {
  padding-right: 10px;
  width: 88px;
}
.details {
  font-size: 12px;
  padding-left: 10px;
  color: #ff6600;
  margin: auto 0;
}
/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/.el-input.is-disabled .el-input__inner {
  background: #fff;
  color: #606266;
  cursor: pointer;
}
</style>