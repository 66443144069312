<template>
  <div class="w" ref="center">
    <div class="table2"></div>
    <header class="centre">
      <div class="left-name">
        <div>
          <img
            :src="this.$store.state.form.avatar == '' ? imgDefault : $showUrl + this.$store.state.form.avatar"
            alt=""
          />
        </div>
        <div class="rightName">
          <p class="messageTop">
            <span>{{ info.nickname }}</span>
            <span class="icon-txt" v-if="info.status === 4">已认证√</span>
          </p>
          <p class="messageBottom">
            <span>电话：</span><span>{{ info.phone | spl }}</span>
          </p>
        </div>
      </div>
      <div class="right">
        <p @click="backfirst" class="back">开始评审</p>
        <el-button class="information" v-if="info.status !== 4" @click="num = 2"
          >完善信息</el-button
        >
      </div>
    </header>
    <div class="bottomCenter">
      <section class="center">
        <components
          @formrenovate="novate"
          :is="com"
          :inform="info"
          class=""
          @updatacom="updata"
          @updataimg="judInfo"
        ></components>
        <!-- 监听num参数 变化切换 -->
        <aside class="side">
          <div
            v-for="sid in side"
            :key="sid.id"
            :class="{ sumside: num === sid.id }"
            @click="num = sid.id"
          >
            <div>{{ sid.name }}</div>
          </div>
        </aside>
      </section>
    </div>
    <Helloworld></Helloworld>
  </div>
</template>
<script>
import Basic from "@/components/basic.vue";
import Helloworld from "@/components/HelloWorld.vue";
import Improve from "@/components/improve.vue";
import Modify from "@/components/modify.vue";
export default {
  components: { Basic, Helloworld, Improve, Modify },
  data () {
    return {
      num: 1,
      side: [

      ],
      imgDefault: require('@/assets/image/head.png'),
      com: "Basic",
      user: {},
      info: {
        nickname: "",
        phone: "",
        address_prov: [],
      }, //个人信息
    };
  },
  watch: {
    num (val) {
      this.changOver(val);
    },
  },
  filters: {
    // 手机号脱敏
    spl (val) {
      if (null != val && val != undefined) {
        var pat = /(\d{3})\d*(\d{4})/;
        return val.replace(pat, '$1****$2');
      } else {
        return "";
      }
    }
  },
  created () { },
  mounted () {
    let a = this.$route.query
    if (a.status) {
      a.status === 4 ? () =>  this.num = 1 :  this.num = 2 
    }
    this.judInfo();
    this.$nextTick(() => {
      // 赛事列表最小高度
      let height = document.querySelector(".header").clientHeight;
      document.querySelector('.table2').style.height = height + 'px';
    });
  },
  methods: {
    // 侧边栏
    handleOpen (key, keyPath) {
    },
    handleClose (key, keyPath) {
    },
    // 切换组件
    changOver (val) {
      val === 1
        ? (this.com = "Basic")
        : val === 2
          ? (this.com = "Improve")
          : val === 3
            ? (this.com = "Modify")
            : this.quit();
    },
    quit () {
      this.$confirm("确认退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sessionStorage.clear();
        this.$message({
          message: "退出登录",
        });
        this.$router.push("/");
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消退出登录",
        });
      });
      // sessionStorage.clear()
      // this.$router.push('/')
    },
    // 更换头像
    updata () {
      this.com = "Improve"
    },
    novate(){
      this.num = 1
    },
    // 评委详细信息
    judInfo () {
      let data2 = {
        token: sessionStorage.getItem("token"),
      };
      this.$api.getinfo({ params: data2 }).then(({ data: { data } }) => {
        this.info = data;
        if (data.status === 4) {
          this.side = [
            { name: "基本信息", id: 1 },
            { name: "完善信息", id: 2 },
            { name: "修改密码", id: 3 },
            { name: "退出登录", id: 4 },]
        } else {
          this.side = [
            { name: "基本信息", id: 1 },
            { name: "完善信息", id: 2 },
            { name: "修改密码", id: 3 },
            { name: "退出登录", id: 4 },]
        }
        this.$store.state.form = data

      });
    },
    backfirst () {
      this.$router.push('/index/list')
    },
  },
};
</script>
<style lang="less" scoped>
.w {
  background: #eee;
  position: relative;
  min-height: 100%;
  width: 100%;
  min-width: 1100px;
}
.bottomCenter {
  padding-bottom: 80px;
  min-width: 1100px;
}

.centre {
  height: 200px;
  padding: 30px 20%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #2f3a59;
  color: #fff;
  align-items: center;
  min-width: 1100px;
  .left-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  .right {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .back {
      color: #fff;
      cursor: pointer;
      font-size: 14px;
    }
    .information {
    }
  }
  .rightName {
    margin-left: 15px;
    .messageTop {
      font-size: 26px;
      margin-bottom: 0;
    }
    .messageBottom {
      font-size: 15px;
    }
  }
  .icon-txt {
    background: rgb(78, 128, 245);
    font-size: 12px;
    padding: 2px 7px;
    border-radius: 4px;
    margin-left: 10px;
  }
  .el-button {
    background: rgb(78, 128, 245);
    color: #fff;
    border: none;
    padding: 7px 12px;
  }
}
.center {
  background: #fff;
  width: 55%;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  left: 5%;
  align-items: center;
  .side {
    position: absolute;
    left: -22%;
    width: 20%;
    top: 0;
    background: #fff;
    border-radius: 10px;
    div {
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 15px;
    }
    div:hover {
      cursor: pointer;
      background-color: #eef3fe;
      color: #4e80f5;
    }
  }

  .sumside {
    color: rgb(78, 128, 245);
    background: rgb(238, 243, 254);
    cursor: pointer;
  }
}
</style>