<template>
  <div class="list" ref="list">
    <!-- <header class="top">
      <div class="image">
        <img src="@/assets/image/login.png" alt="" />
      </div>
    </header> -->
    <!-- tab切换栏 -->
    <div class="table2"></div>
    <div class="table">
      <div style="display: flex; width: 70%">
        <div
          class="tableList"
          v-for="tab in tabList"
          :key="tab.id"
          :class="{ 'tab-right': tabRight === tab.id }"
          @click="getjudeMatch(tab.id,1)"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 赛事列表 -->
      <div class="match">
        <div class="match-cell" v-for="(item, index) in list" :key="index">
          <div class="cell">
            <div class="img">
              <img
                :src="
                 $showUrl +
                  item.banner.split(',')[0]
                "
                alt=""
              />
            </div>
            <div class="title">
              <div class="titleLeft" style="width: 75%">
                <p class="titleName">{{ item.name }}</p>
                <p class="titleTime">
                  <span class="color">评审时间：</span>
                  <span class="time">
                    {{ item.plan_begin | aoe }} ~ {{ item.plan_end | aoe }}
                  </span>
                </p>
              </div>
              <div>
                <el-button
                  type="primary"
                  v-show="item.status === 1"
                  round
                  @click="goevaluate(item)"
                  >进入评审</el-button
                >
                <el-button type="info" v-show="item.status === 0" round
                  >未开始</el-button
                >
                <el-button type="info" v-show="item.status === 2" round
                  >已结束</el-button
                >
              </div>
            </div>
            <!-- <el-row class="num-txt">
              <el-col :span="8" class="center">
                <div class="centerTop">参赛数目</div>
                <div class="num">{{ item.attend_num }}</div>
              </el-col>
              <el-col :span="8" class="center">
                <div class="centerTop">评委数目</div>
                <div class="num">{{ item.judge_num }}</div>
              </el-col>
              <el-col :span="8" class="center">
                <div class="centerTop">已评数目</div>
                <div class="num">{{ item.review_num }}</div>
              </el-col>
            </el-row> -->
          </div>
        </div>
      </div>
      <el-pagination
        style="display: flex; justify-content: center"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <HelloWorld></HelloWorld>
  </div>
</template>
<script>
import HelloWorld from "@/components/HelloWorld.vue";
export default {
  components: { HelloWorld },
  data () {
    return {
      tabList: [
        { name: "全部赛事", id: 1 },
        { name: "进行中", id: 2 },
        { name: "已结束", id: 3 },
      ],
      tabRight: 1,
      matchList: [],
      token: "",
      list: [],
      searchList: "",
      total: 0,
    
    };
  },
  filters: {
    aoe (ns) {
      var date = new Date(ns * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '.';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
      var D = date.getDate() + ' ';
      var h = date.getHours() + ':';
      var m = date.getMinutes();
      var s = date.getSeconds();
      return Y + M + D ;
    }
  },
  mounted () {

    // 赛事列表最小高度
    let height = document.querySelector(".header").clientHeight;
    document.querySelector('.table2').style.height = height + document.querySelector(".table").clientHeight + 'px';
    document.querySelector(".list").style.minHeight =
      document.querySelector("body").clientHeight + 'px';
    this.token = sessionStorage.getItem("token");
    this.getjudeMatch(1,1);
    // 判断是否是登录跳转
    let a = this.$route.query.once
    if(a){
      this.judInfo()
    }
  },
  methods: {
    // 评委详细信息
    judInfo () {
      let data2 = {
        token: sessionStorage.getItem("token"),
      };
      this.$api.getinfo({ params: data2 }).then(({ data: { data } }) => {
        // console.log(data.status);
        this.$store.state.form = data
        if(data.status!==4){
          this.$router.push({
            path:"/index/centre",
            query:{
              status:data.status
            }
          })
        }

      });
    },
    // 评审赛事列表
    getjudeMatch (id,val) {
      this.tabRight = id;
      let data = {
        token: this.token,
        status: id,
        page: val,
        size: 10,
      };
      this.$api.judeMatch({ params: data }).then((res) => {
        this.list = res.data.list;
        this.total = res.data.total
      });
    },
    // 跳转页面
    goevaluate (val) {
      this.$router.push({
        path: "/index/psList",
        query: {
          id: val.id,
          name: val.name,
          begin: val.plan_begin,
          end: val.plan_end,
        },
      });
    },
    handleCurrentChange(val){
      this.getjudeMatch(this.tabRight,val)
    }
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 1000px) {
  .titleLeft {
    width: 65% !important;
  }
  .list .content .match .img {
    height: 125px !important;
  }
  .table {
    padding: 15px 7% !important;
  }
}
@media screen and (max-width: 1030px) {
  .titleLeft {
    width: 65% !important;
  }
  .table {
    padding: 14px 7% !important;
    top: 60px !important;
  }
}
.list {
  background: #eee;
  position: relative;
  .image {
    width: 100%;
    height: 300px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .table {
    display: flex;
    padding: 15px 7%;
    background: #fff;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 54px;
    .tableList {
      margin: 0 5px;
      width: 100px;
      height: 20px;
      padding: 7px 15px;
      text-align: center;
      border-radius: 16px;
      line-height: 20px;
      cursor: pointer;
    }
    .tableList:hover {
      background: rgb(78, 128, 245);
      color: #fff;
      border-radius: 16px;
      cursor: pointer;
    }
    .el-col {
      display: flex;
      justify-content: center;
    }

    .tab-right {
      background: rgb(78, 128, 245);
      color: #fff;
      text-align: center;
      border-radius: 16px;
      line-height: 20px;
    }
    .tab-search {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .el-input {
        width: 70%;
        /deep/.el-input__inner {
          border-radius: 20px;
        }
        /deep/.el-input__inner {
          &::placeholder {
            text-align: center;
          }
        }
      }
    }
  }
  .content {
    width: 70%;
    margin: 0 auto;
    padding: 0px 0 120px 0;
    .el-button--info {
      background: #ccc;
      border-color: #ccc;
    }
    .match {
      // padding: 0px 10% 20px;
      background: #eee;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 70px;
      .match-cell {
        border-radius: 7px;
        width: 49%;
        margin-top: 25px;
        overflow: hidden;
        .cell {
          border-radius: 7px;
          background: #fff;
        }
      }
      .match-cell:hover {
        box-shadow: 0 0 10px #4e80f5;
      }
      .img {
        width: 100%;
        height: 240px;
        transition: all 0.5s;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.5s;
          position: absolute;
        }
      }
      .img:hover img {
        transform: scale(1.2);
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 15px;
        border-bottom: 1px solid #f2f2f2;
        .titleLeft {
          width: 75%;
        }
        .titleName {
          font-size: 20px;
          margin: 0;
          font-weight: 600;
          padding-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .titleTime {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .color {
          color: rgb(78, 128, 245);
          font-size: 14px;
        }
        .time {
          font-size: 14px;
          color: #4e80f5;
        }
        .el-button.is-round {
          padding: 8px 15px;
        }
      }
      .num-txt {
        padding: 12px 0 12px;
        .num {
          color: rgb(78, 128, 245);
          font-size: 20px;
        }
      }
      .center {
        text-align: center;
        .centerTop {
          color: #666;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>