<template>
  <div>
    <div class="title">基本信息</div>
    <div class="content">
      <div class="flex just">
        <div class="headPicture">
          <div>头像：</div>
          <div class="picture">
            <img
              :src="list.avatar == '' ? imgDefault : $showUrl + list.avatar"
              alt=""
            />
          </div>
        </div>
        <div>
          <!-- <el-button @click="touxiang">更换头像</el-button> -->
          <el-upload
            class="upload-demo"
            :action="$uploadUrl + '/api/upload/push'"
            multiple
            :show-file-list="false"
            :auto-upload="false"
            :on-change="fileChange1"
            :headers="{ 'Content-Type': 'multipart/form-data' }"
          >
            <el-button size="small" type="primary">更换头像</el-button>
          </el-upload>
        </div>
      </div>
      <div class="flex" v-show="this.list.nickname != ''">
        <div class="left">昵称：</div>
        <div>{{ list.nickname }}</div>
      </div>
      <div class="flex" v-show="this.list.phone != ''">
        <div class="left">手机号：</div>
        <div>{{ list.phone | spl }}</div>
      </div>
      <div class="flex" v-show="this.list.telephone != ''">
        <div class="left">联系方式：</div>
        <div>{{ list.telephone | spl }}</div>
      </div>
      <div class="flex" v-show="this.list.realname != ''">
        <div class="left">真实姓名：</div>
        <div>{{ list.realname }}</div>
      </div>
      <div class="flex" v-show="this.list.cardid != ''">
        <div class="left">身份证号：</div>
        <div>{{ list.cardid | spl }}</div>
      </div>
      <!-- <div class="flex" v-show="this.list.wx_account != ''">
        <div class="left">微信号：</div>
        <div>{{ list.wx_account }}</div>
      </div> -->
      <!-- <div class="flex" v-show="this.list.birthday != ''">
        <div class="left">出生年月：</div>
        <div>{{ list.birthday }}</div>
      </div> -->
      <div class="flex" v-show="this.list.gender != ''">
        <div class="left">性别：</div>
        <div>
          {{
            list.gender === 0
              ? "保密"
              : list.gender === 1
              ? "男"
              : list.gender === 2
              ? "女"
              : "保密"
          }}
        </div>
      </div>
      <div class="flex" v-show="this.list.education != ''">
        <div class="left">学历：</div>
        <div>{{ list.education }}</div>
      </div>
      <div class="flex" v-show="this.list.company != ''">
        <div class="left">单位名称：</div>
        <div>{{ list.company }}</div>
      </div>
      <div class="flex" v-show="this.list.company != ''">
        <div class="left">所在单位简介：</div>
        <div>{{ list.company_description }}</div>
      </div>
      <div class="flex" v-show="this.list.address_prov != ''">
        <div class="left">地区：</div>
        <div>{{ list.address_prov }}</div>
      </div>
      <div class="flex" v-show="this.list.position != ''">
        <div class="left">职位：</div>
        <div>{{ list.position }}</div>
      </div>
      <div class="flex" v-show="this.list.addressid != ''">
        <div class="left">联系地址：</div>
        <div>{{ list.addressid }}</div>
      </div>
      <div class="flex" v-show="this.list.address != ''">
        <div class="left">详细地址：</div>
        <div>{{ list.address }}</div>
      </div>
      <div class="flex" v-show="this.list.email != ''">
        <div class="left">邮箱：</div>
        <div>{{ list.email }}</div>
      </div>
      <div class="flex" v-show="this.list.user_type != ''">
        <div class="left">评委类型：</div>
        <div v-show="list.user_type == 1">创业导师</div>
        <div v-show="list.user_type == 2">投资人</div>
        <div v-show="list.user_type == 3">专家学者</div>
        <div v-show="list.user_type == 0">未选择</div>
      </div>
      <!-- <div class="flex" v-show="this.list.status!=''">
        <div class="left">审核状态：</div>
        <div v-show="list.status==1">未提交</div>
        <div v-show="list.status==2">待审核</div>
        <div v-show="list.status==3">认证成功</div>
        <div v-show="list.status==4">被拒绝</div>
      </div> -->
      <div v-if="list.user_type == 1">
        <div class="flex" v-show="this.list.experience != ''">
          <div class="left">从业经验：</div>
          <div>{{ list.experience }}</div>
        </div>
        <div class="flex" v-show="this.list.demo != ''">
          <div class="left">主要业绩及案例：</div>
          <div>{{ list.demo }}</div>
        </div>
        <div class="flex" v-show="this.list.honor != ''">
          <div class="left">获得社会荣誉：</div>
          <div>{{ list.honor }}</div>
        </div>
        <div class="flex" v-show="this.list.description_shcolar != ''">
          <div class="left">个人简介：</div>
          <div>{{ list.description_shcolar || "" }}</div>
        </div>
        <div class="flex" v-show="this.list.recommend != ''">
          <div class="left">推荐机构：</div>
          <div>{{ list.recommend }}</div>
        </div>
      </div>
      <div v-if="list.user_type == 2">
        <div class="flex" v-show="this.list.ide_category != ''">
          <div class="left">身份类别：</div>
          <div>{{ list.ide_category }}</div>
        </div>
        <div class="flex" v-show="this.list.invest_stage != ''">
          <div class="left">投资阶段：</div>
          <div>{{ list.invest_stage }}</div>
        </div>
        <!-- <div class="flex" v-show="this.list.invest_address != ''">
          <div class="left">投资地区：</div>
          <div>{{ list.invest_address }}</div>
        </div> -->
        <div class="flex" v-show="this.list.project_demo != ''">
          <div class="left">投资关注领域：</div>
          <div>{{ list.project_demo }}</div>
        </div>
        <!-- <div class="flex" v-show="this.list.invest_quota != ''">
          <div class="left">投资额度：</div>
          <div>{{ list.invest_quota }}</div>
        </div> -->
        <!-- <div class="flex" v-show="this.list.currency_type != ''">
          <div class="left">币种类型：</div>
          <div>{{ list.currency_type }}</div>
        </div> -->
        <div class="flex" v-show="this.list.resources != ''">
          <div class="left">提供的资源：</div>
          <div>{{ list.resources }}</div>
        </div>
        <!-- <div class="flex" v-show="this.list.business_name != ''">
          <div class="left">所在企业名称：</div>
          <div>{{ list.business_name }}</div>
        </div>
        <div class="flex" v-show="this.list.business_address != ''">
          <div class="left">企业地址：</div>
          <div>{{ list.business_address }}</div>
        </div>
        <div class="flex" v-show="this.list.business_content != ''">
          <div class="left">企业介绍：</div>
          <div>{{ list.business_content }}</div>
        </div> -->
        <div class="flex" v-show="this.list.invest_case != ''">
          <div class="left">案例：</div>
          <div>
            <div
              v-for="(xcase, index) in list.invest_case"
              :key="index"
              style="margin-bottom: 5px"
            >
              {{
                xcase.id +
                "," +
                xcase.name +
                "," +
                xcase.address +
                "," +
                xcase.invest_amount +
                "," +
                xcase.invest_time
              }}
            </div>
          </div>
        </div>
        <div class="flex" v-show="this.list.resources != ''">
          <div class="left">推荐机构：</div>
          <div>{{ list.invest_recommend }}</div>
        </div>
        <div class="flex" v-show="this.list.invest_experience != ''">
          <div class="left">主要从业经历：</div>
          <div>{{ list.invest_experience }}</div>
        </div>
        <div class="flex" v-show="this.list.invest_honor != ''">
          <div class="left">获得的社会荣誉：</div>
          <div>{{ list.invest_honor }}</div>
        </div>
      </div>
      <div v-if="list.user_type == 3">
        <div class="flex" v-show="this.list.research != ''">
          <div class="left">研究领域：</div>
          <div>{{ list.research }}</div>
        </div>
        <div class="flex" v-show="this.list.experience_shcolar != ''">
          <div class="left">主要从业经历：</div>
          <div>{{ list.experience_shcolar }}</div>
        </div>
        <div class="flex" v-show="this.list.demo_shcolar != ''">
          <div class="left">主要著作及学术成功：</div>
          <div>{{ list.demo_shcolar }}</div>
        </div>
        <div class="flex" v-show="this.list.honor_shcolar != ''">
          <div class="left">社会荣誉：</div>
          <div>{{ list.honor_shcolar }}</div>
        </div>
        <!-- <div class="flex" v-show="this.list.description_shcolar != ''">
          <div class="left">个人简介：</div>
          <div>{{ list.description_shcolar }}</div>
        </div> -->
        <div class="flex" v-show="this.list.recommend_shcolar != ''">
          <div class="left">推荐机构：</div>
          <div>{{ list.recommend_shcolar }}</div>
        </div>
      </div>
      <div class="flex">
        <div class="left">专项赛评审意向：</div>
        <div>{{ this.list.intention }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageUrl: "",
      list: {},
      imgDefault: require("@/assets/image/head.png"),
      alist: {},
    };
  },
  filters: {
    spl(val) {
      if (null != val && val != undefined) {
        var pat = /(\d{3})\d*(\d{4})/;
        return val.replace(pat, "$1****$2");
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.judInfo();
  },
  methods: {
    // 个人信息
    judInfo() {
      let data2 = {
        token: sessionStorage.getItem("token"),
      };
      this.$api.getinfo({ params: data2 }).then(({ data: { data } }) => {
        this.list = data;
        this.alist = JSON.parse(JSON.stringify(data));
        this.getident();
        this.getphase();
        this.getprov();
        this.getindustry();
        this.getstment();
        // this.getamountType();
        this.geteducation();
        this.getAddress();

        if (data.intention) {
          let a = data.intention.split(",");
          let b = "";
          a.forEach((item, index) => {
            if (item === "1") {
              if (index === a.length - 1) {
                b = b + "科技创新专项赛";
              } else {
                b = b + "科技创新专项赛,";
              }
            }
            if (item === "2") {
              if (index === a.length - 1) {
                b = b + "乡村振兴专项赛";
              } else {
                b = b + "乡村振兴专项赛,";
              }
            }
            if (item === "3") {
              if (index === a.length - 1) {
                b = b + "数字经济专项赛";
              } else {
                b = b + "数字经济专项赛,";
              }
            }
            if (item === "4") {
              if (index === a.length - 1) {
                b = b + "社会企业专项赛";
              } else {
                b = b + "社会企业专项赛,";
              }
            }
          });
          this.list.intention = b;
          console.log(data);
        }
      });
    },
    // 获取身份类别
    async getident() {
      let res = await this.$api.ident();
      let arr = [];
      if (this.list.ide_category.length > 0) {
        this.list.ide_category.forEach((element) => {
          let aa = res.data.data.filter((is) => {
            return is.id == element;
          });
          if (aa.length > 0) arr.push(aa[0].name);
        });
      }
      this.list.ide_category = arr.join();
    },
    // 获取投资阶段
    async getphase() {
      let res = await this.$api.phase();
      let arr = [];
      if (this.list.invest_stage.length > 0) {
        this.list.invest_stage.forEach((element) => {
          let aa = res.data.data.filter((is) => {
            return is.id == element;
          });
          if (aa.length > 0) arr.push(aa[0].name);
        });
      }
      this.list.invest_stage = arr.join();
    },
    // 获取投资地区
    async getprov() {
      let res = await this.$api.prov();
      let arr = [];
      if (this.list.invest_address.length > 0) {
        this.list.invest_address.forEach((element) => {
          let aa = res.data.data.filter((is) => {
            return is.id == element;
          });
          arr.push(aa[0].name);
        });
      }
      this.list.invest_address = arr.join();
    },
    // 获取投资关注领域
    async getindustry() {
      let res = await this.$api.industry();
      let arr = [];
      if (this.list.invest_field.length > 0) {
        this.list.invest_field.forEach((element) => {
          let aa = res.data.data.filter((is) => {
            return is.id == element;
          });
          if (aa.length > 0) arr.push(aa[0].name);
        });
      }
      this.list.invest_field = arr.join();
    },
    // 获取投资额度
    async getstment() {
      let res = await this.$api.stment();
      let arr = [];
      if (this.list.invest_quota.length > 0) {
        this.list.invest_quota.forEach((element) => {
          let aa = res.data.data.filter((is) => {
            return is.id == element;
          });
          arr.push(aa[0].name);
        });
      }
      this.list.invest_quota = arr.join();
    },
    // 获取币种类型
    async getamountType() {
      let res = await this.$api.amountType();
      let arr = [];
      let aa = res.data.data.filter((is) => {
        return is.id == this.list.currency_type;
      });
      arr.push(aa[0].name);
      this.list.currency_type = arr.join();
    },
    // 获取联系地址
    async getAddress() {
      let a = this.list.addressid.split(",");
      let form = new FormData();
      form.append("code", a[a.length - 1]);
      let {
        data: { data },
      } = await this.$api.addstr(form);
      this.list.addressid = data;
    },
    // 学历
    async geteducation() {
      let res = await this.$api.education();
      let arr = [];
      let aa = res.data.data.filter((is) => {
        return is.id == this.list.education;
      });
      arr.push(aa[0].name);
      this.list.education = arr.join();
    },
    // touxiang () {
    //   this.$emit('updatacom')
    // },
    fileChange1(file) {
      this.imageUrl = file.raw;
      let formData = new FormData();
      formData.append("imgfile", this.imageUrl);
      formData.append("token", sessionStorage.getItem("token"));
      this.$api.upload(formData).then((res) => {
        this.$set(this.list, "avatar", res.data.data.url);
        this.touxiang();
      });
      // formData.append('file', this.file);
    },

    touxiang() {
      var info = new FormData();
      info.append("token", sessionStorage.getItem("token"));
      info.append("avatar", this.list.avatar);
      this.$api
        .upavtar(info)
        .then(({ data: { code, msg } }) => {
          if (code === 1) {
            this.$emit("updataimg");
          } else {
            this.$message.error(msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  .title {
    color: rgb(112, 111, 111);
    padding-left: 20px;
    font-size: 14px;
    margin: 0 0 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  .el-button {
    background: rgb(78, 128, 245);
    color: #fff;
    border: none;
    padding: 7px 12px;
  }
}
.flex {
  display: flex;
  padding: 20px 20px;
  border-bottom: 1px solid #eee;
  .left {
    width: 120px;
  }
}
.just {
  justify-content: space-between;
  align-items: center;
}
.headPicture {
  display: flex;
}
.picture {
  img {
    width: 100px;
  }
}
</style>
