<template>
  <div class="psList">
    <div class="table2"></div>
    <div class="backBox">
      <div class="top" @click="back">返回列表</div>
    </div>
    <div class="content">
      <div class="title">{{ title }}</div>
      <div class="time">
        <span class="timeLeft">评审时间：</span
        ><span class="timeRight">{{ begin | aoe }} ~ {{ end | aoe }}</span>
      </div>
      <div class="progress">
        <span class="psPro">评审进度：</span>
        <span class="pro">
          <el-progress
            :percentage="jindu"
            :format="format"
            :stroke-width="15"
          ></el-progress>
        </span>
      </div>
      <div class="line"></div>
      <div class="listTop">
        <div style="display: flex">
          <div
            class="list"
            @click="changeTab(tab.id)"
            v-for="tab in tabList"
            :key="tab.id"
            :class="{ list1: num === tab.id }"
          >
            {{ tab.name }}<span>{{ tab.nums }}</span>
          </div>
        </div>
        <div class="flex">
          <el-input
            placeholder="请输入项目编号"
            v-model="code"
            @keyup.enter.native="handcode"
          ></el-input>
          <el-button type="primary" @click="handcode" icon="el-icon-search"
            >搜索</el-button
          >
        </div>
      </div>
      <!-- 评委评审确认表单下载，注：只有全部评审完才显示 -->
      <div class="assessement_form" v-show="num === 2 && this.all_done == 1" >
        <div class="assessement_left">
          评审信息确认表
          <p>请评委下载到本地后，签字确认后发送给组委会</p>
        </div>
        <div class="assessement_right">
          <span class="assessement_title" @click="downloadForm()">点击下载</span>
        </div>
      </div>
      <!-- 待评审 -->
      <div v-show="num === 0" class="text">
        <el-empty
          description="待评审为空"
          v-show="this.txtListNot.length === 0"
        ></el-empty>
        <div class="txt" v-for="(item, index) in txtListNot" :key="index">
          <div class="txtLeft">
            <div class="txtUp">
              <span v-html="item.name"></span>
            </div>
            <div class="leftDown">
              <span>编号：</span>
              <span>{{ item.project_code }}</span>
            </div>
          </div>
          <div class="txtRight">
            <el-button type="primary" v-show="item.type!=='3'" @click="score(item, 1)"
              >查看项目</el-button
            >
            <el-button type="primary" v-show="item.type==='3'" @click="recommend_score_detail(item, 1)"
              >投票并打分</el-button
            >
          </div>
        </div>
      </div>
      <!-- 待提交 -->
      <div v-show="num == 1" class="text">
        <el-empty
          description="待提交为空"
          v-show="this.txtListDTJ.length == 0"
        ></el-empty>
        <div class="txt" v-for="(item, index) in txtListDTJ" :key="index">
          <div class="txtLeft">
            <div class="txtUp">
              <span v-html="item.name"></span>
            </div>
            <div class="leftDown">
              <span>编号：</span>
              <span>{{ item.project_code || item.project_id }}</span>
            </div>
          </div>
          <div class="txtRight">
            <div style="font-weight: 600" v-if="item.type === 0">
              得分：{{ Number(item.scores).toFixed(2) }}
            </div>
            <el-button type="primary" @click="score(item, 2)">{{
              item.type === 0
                ? "修改"
                : item.type === 1
                ? "推荐"
                : item.type === 2
                ? "投票"
                : "修改"
            }}</el-button>
            <el-button
              type="primary"
              v-if="item.type === 0"
              @click="submit(item)"
              >提交</el-button
            >
          </div>
        </div>
      </div>
      <!-- 已完成 -->
      <div v-show="num == 2" class="text">
        <el-empty
          description="已评审为空"
          v-show="this.txtListYWC.length == 0"
        ></el-empty>
        <div class="txt" v-for="(item, index) in txtListYWC" :key="index">
          <div class="txtLeftywc">
            <div class="left">
              <div class="txtUp">
                <span v-html="item.name"></span>
              </div>
              <div class="leftDown">
                <span>编号：</span>
                <span>{{ item.project_code || item.project_id }}</span>
              </div>
            </div>
            <div class="right">
              <span class="rightScore" v-if="item.type === 3"
                >得分：<span class="score">{{
                  Number(item.scores).toFixed(2)
                }}</span></span
              >
              <span class="rightScore" v-if="item.type != 0">{{
                item.type === 1 && item.is_recommend === 1
                  ? "已推荐"
                  : item.type === 1 && item.is_recommend === 0
                  ? "未推荐"
                  : item.type === 2
                  ? "已投票"
                  : ""
              }}</span>

              <el-button type="primary" @click="score(item, 3)">详情</el-button>
              <el-button
                v-if="item.type === 1 && item.is_recommend === 1"
                type="primary"
                @click="cantuijian(item)"
                >取消推荐</el-button
              >
              <el-button
                v-if="item.type === 1 && item.is_recommend === 0"
                type="primary"
                @click="editTj(item)"
                >推荐</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagetotal"
        :page-size="10"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <Helloworld class="pageBottom"></Helloworld>
  </div>
</template>

<script>
import Helloworld from '@/components/HelloWorld.vue';
import download from 'downloadjs';

export default {
  components: { Helloworld },
  data () {
    return {
      id: null,//赛事id
      title: '',//赛事名
      begin: '',//时间
      end: '',//时间
      pagetotal: 0,
      token: '',
      talnum: null,//当前已评审数量
      num: 0,
      total: 0,//报名总数
      jindu: 0,
      waitTotal: '',//带评审
      code: '',//编号
      tabList: [
        { name: "待评审", id: 0, nums: 0 },
        { name: "待提交", id: 1, nums: 0 },
        { name: "已评审", id: 2, nums: 0 },
      ],
      txtListNot: [],//   待评审数据列表
      txtListDTJ: [],//   待提交列表
      txtListYWC: [],//   已完成列表
      review_file_path:'',  //评审信息确认表
    };
  },
  watch: {
    num (val) {
      window.sessionStorage.setItem('num', this.num)
      val === 0 ? this.getxcxNull(1) : val === 1 ? this.getscxCom(1, 1) : val === 2 ? this.getscxSuc(1, 2) : ''
    }
  },
  filters: {
    aoe (ns) {
      var date = new Date(ns * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '.';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
      var D = date.getDate() + ' ';
      var h = date.getHours() + ':';
      var m = date.getMinutes();
      var s = date.getSeconds();
      return Y + M + D;
    }
  },
  created () {
    this.id = this.$route.query.id
    this.title = this.$route.query.name
    this.begin = this.$route.query.begin
    this.end = this.$route.query.end
    this.token = sessionStorage.getItem('token')
    this.getGress()
  },
  mounted () {
    if (window.sessionStorage.getItem('num') || window.sessionStorage.getItem('page')) {
      let num = window.sessionStorage.getItem('num')
      let page = window.sessionStorage.getItem('page')
      if (num && num == 0) {
        this.num = 0
      } else if (num && num == 1) {
        this.num = 1
      } else if (num && num == 2) {
        this.num = 2
      }
      this.getxcxNull(page);//未评审
      this.getscxCom(page, 1);//已完成--保存
      this.getscxSuc(page, 2);//已完成--提交
      this.pagetotal = this.tabList[0].nums
    } else {
      this.getxcxNull(1);//未评审
      this.getscxCom(1, 1);//已完成--保存
      this.getscxSuc(1, 2);//已完成--提交
      this.pagetotal = this.tabList[0].nums
    }
    let height = document.querySelector(".header").clientHeight;
    document.querySelector('.table2').style.height = height + 'px';
    document.querySelector(".psList").style.minHeight =
      document.querySelector("body").clientHeight - 80 + 'px';
  },
  methods: {
    // 下载--文件
    async downloadForm(){
      // download(this.review_file_path);
      // window.open(this.review_file_path);
      //window.location.href = this.review_file_path
      let formdata = new FormData();
      formdata.append("token", sessionStorage.getItem("token"));
      formdata.append("match_id", this.id);
      let { data } = await this.$api.getReviewList(formdata)
      if (data.code === 1) {
        if (data.data.review_file_path != '') {
          window.location.href = data.data.review_file_path
        } else {
          this.$message.error('获取评审信息确认表失败，请联系管理员');
        }
      } else {
        this.$message.error(data.msg);
      }
    },
    //tab状态切换：
    changeTab(index){
      this.num = index;
    },
    //推荐并打分按钮，跳转详情页
    recommend_score_detail(item,state){
      this.$router.push({
        path: '/index/score',
        query: {
          id: item.project_id,
          pingfen: state,
          type: item.type
        }
      })
    },
    html_encode: function (str) {
      var s = ''
      if (str.length === 0) return ''
      s = str.replace(/&/g, '&amp;')
      // s = s.replace(/</g, '&lt;')
      // s = s.replace(/>/g, '&gt;')
      // s = s.replace(/ /g, '&nbsp;')
      // s = s.replace(/'/g, '&#39;')
      // s = s.replace(/"/g, '&quot;')
      // s = s.replace(/\n/g, '<br>')
      return s
    },
    html_decode: function (str) {
      var s = ''
      if (str.length === 0) return ''
      s = str.replace(/&amp;/g, '')
      s = str.replace(/amp;/g, '')
      s = s.replace(/&lt;/g, '<')
      s = s.replace(/&gt;/g, '>')
      s = s.replace(/&nbsp;/g, ' ')
      s = s.replace(/&#39;/g, '\'')
      s = s.replace(/&quot;/g, '"')
      s = s.replace(/<br>/g, '\n')
      return s
    },

    // 修改
    modify () {
      this.$router.push('/index/score')
    },
    // 提交
    async submit (val) {
      this.$confirm('提交后成绩不可修改，请谨慎提交。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let form = {
          token: sessionStorage.getItem("token"),
          project_id: val.project_id,
        }
        let { data: { data: tableData } } = await this.$api.Rule({ params: form })
        if (tableData.is_recommend == 0 && tableData.opinion.length < 25) {
          return this.$message.error("请填写评审意见，至少25个字");
        }
        if (tableData.is_tsign == 1 && tableData.signature_img == '') {
          return this.$message.error("提交前需要评委签字");
        }
        let formdata = new FormData();
        formdata.append("token", sessionStorage.getItem("token"));
        tableData.rule_index.forEach((item, index) => {
          formdata.append(`scores[${index}][rule_id]`, item.rule_id);
          formdata.append(`scores[${index}][id]`, item.id);
          formdata.append(`scores[${index}][project_id]`, item.project_id);
          formdata.append(`scores[${index}][scores]`, item.scores);
        });
        formdata.append(`is_recommend`, tableData.is_recommend);
        formdata.append(`opinion`, tableData.opinion);
        if (tableData.is_tsign == 1) {
          formdata.append("signature", tableData.signature_img);
        }

        let { data: { code, msg } } = await this.$api.scores(formdata)
        if (code === 1) {
          this.$message.success("评分成功");
          this.getscxCom(1, 1);//已完成--保存
          this.getscxSuc(1, 2);//已评审
        } else {
          this.$message.error(msg);
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        });
      });

    },
    // 未评审
    getxcxNull (val) {
      let data = {
        token: this.token,
        page: val,
        size: 10,
        match_id: this.id,
        code: this.code
      }
      this.$api.xcxNull({ params: data }).then(({ data }) => {
        this.txtListNot = data.data.list
        this.tabList[0].nums = data.data.total
        this.pagetotal = data.data.total
      })
    },
    // 已完成 -- 待提交
    getscxCom (val, state) {
      let data = {
        token: this.token,
        page: val,
        size: 10,
        match_id: this.id,
        status: state,
        code: this.code
      }
      this.$api.scxSuc({ params: data }).then(({ data }) => {
        this.txtListDTJ = data.data.list
        this.tabList[1].nums = data.data.total
        this.pagetotal = data.data.total
      })
    },
    // 已完成--已评审
    getscxSuc (val, state) {
      let data = {
        token: this.token,
        page: val,
        size: 10,
        match_id: this.id,
        status: state,
        code: this.code
      }
      this.$api.scxSuc({ params: data }).then(({ data }) => {
        this.txtListYWC = data.data.list
        this.review_file_path = data.data.review_file_path
        this.all_done = data.data.all_done
        this.tabList[2].nums = data.data.total
        this.pagetotal = data.data.total
      })
    },
    back () {
      this.$router.push('/index/list')
    },
    // 跳转详情页面
    score (val, state) {
      this.$router.push({
        path: '/index/score',
        query: {
          id: val.project_id,
          pingfen: state,
          type: val.type
        }
      })
    },

    // 取消推荐 -已评审修改状态
    cantuijian (val) {
      this.$confirm('确定取消推荐?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let formdata = new FormData()
        formdata.append('token', sessionStorage.getItem("token"))
        formdata.append('project_id', val.project_id)
        let res = await this.$api.deltuijian(formdata)
        if (res.data.code === 1) {
          this.$message.success('已取消推荐')
          this.getxcxNull(1);//未评审
          this.getscxCom(1, 1);//已完成--保存
          this.getscxSuc(1, 2);//已完成--提交
        }
      })
        .catch(err => {
        })
    },
    // 推荐-已评审修改状态
    editTj (val) {
      this.$confirm("确定推荐？", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let formdata = new FormData()
        formdata.append('token', sessionStorage.getItem("token"))
        formdata.append('project_id', val.project_id)
        let { data } = await this.$api.tuijian(formdata)
        if (data.code == 1) {
          this.getxcxNull(1);//未评审
          this.getscxCom(1, 1);//已完成--保存
          this.getscxSuc(1, 2);//已完成--提交
          this.$message.success(data.msg)
        }
      }).catch(() => { })


    },

    format (percentage) {
      return percentage === 100 ? "已完成" : `已完成：${percentage}%`;
    },
    handleCurrentChange (val) {
      window.sessionStorage.setItem('page', val)
      // this.getxcxNull(val);//未评审
      // this.getscxSuc(val);//已完成
      this.num === 0 ? this.getxcxNull(val) : this.num === 1 ? this.getscxCom(val, 1) : this.num === 2 ? this.getscxSuc(val, 2) : ''
    },
    // 评审进度
    getGress () {
      let form = new FormData()
      form.append('token', this.token)
      form.append('match_id', this.id)
      this.$api.gress(form).then(({ data: { data: { num, total } } }) => {
        this.jindu = total == 0 ? 0 : Math.round(num / total * 100)
      })
    },
    handcode () {
      this.num === 0 ? this.getxcxNull(1) : this.num === 1 ? this.getscxCom(1, 1) : this.num === 2 ? this.getscxSuc(1, 2) : ''
    }
  },
};
</script>

<style lang="less" scoped>
.psList {
  width: 100%;
  background-color: #eeeeee;
  position: relative;
  padding-bottom: 80px;
  .backBox {
    width: 65%;
    margin: 0 auto;

    .top {
      padding-top: 30px;
      color: #999999;
      font-size: 14px;
    }
    .top:hover {
      color: #4e80f5;
      cursor: pointer;
    }
  }
  .content {
    width: 65%;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 15px;
    background-color: #fff;
    padding-bottom: 50px;
    .title {
      font-size: 30px;
      font-weight: 600;
      padding: 15px 15px 0 15px;
    }
    .time {
      display: flex;
      align-items: center;
      margin: 20px 0;
      padding-left: 15px;
      .timeLeft {
        color: #4e80f7;
        font-size: 15px;
      }
      .timeRight {
        font-size: 15px;
      }
    }
    .progress {
      display: flex;
      align-items: center;
      padding-left: 15px;
      .psPro {
        font-size: 15px;
        width: 78px;
      }
      .pro {
        width: 90%;
      }
    }
    .line {
      width: 100%;
      border-top: 1px solid #f2f2f2;
      margin: 20px 0;
    }
    .listTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      margin: 20px 0;
      .list {
        padding: 0 14px;
        height: 40px;
        line-height: 40px;
        background-color: #f2f2f2;
        color: #666666;
        text-align: center;
        border-radius: 25px;
        margin: 0 20px;
        span {
          margin-left: 10px;
        }
      }
      .list:hover {
        background-color: #4e80f5;
        color: #fff;
        cursor: pointer;
      }
      .list1 {
        padding: 0 14px;
        height: 40px;
        line-height: 40px;
        background-color: #4e80f5;
        color: #fff;
        text-align: center;
        border-radius: 25px;
        margin: 0 20px;
        span {
          margin-left: 10px;
        }
      }
    }
    .text {
      width: 90%;
      margin: 0 auto;
      .txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        padding: 15px 0 15px 0;
        .txtLeft {
          width: 90%;
          .txtUp {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .leftDown {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #999999;
          }
        }
        .txtLeftDTJ {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            .txtUp {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .leftDown {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #999999;
            }
          }
          .right {
            display: flex;
            align-items: center;
            font-size: 16px;
            width: 100px;
            margin-left: 10px;
            margin-right: 2px;
            .score {
              color: #4e80f5;
            }
          }
        }
        .txtLeftywc {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            .txtUp {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .leftDown {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #999999;
            }
          }
          .right {
            display: flex;
            align-items: center;
            font-size: 16px;
            .rightScore {
              width: 110px;
              font-size: 16px;
              font-weight: 600;
              .score {
                color: #4e80f5;
              }
            }
          }
        }
        .txtRight {
          margin-right: 10px;
          display: flex;
          .el-button {
            border-radius: 30px;
            // padding: 12px 35px;
            background-color: #4e80f5;
            font-size: 16px;
          }
        }
        .txtRightDTJ {
          display: flex;
          align-items: center;
          margin-right: 10px;
          .modify {
            border-radius: 30px;
            // padding: 12px 35px;
            background-color: #f2f2f2 !important;
            font-size: 16px;
            color: #757575;
            border: 0;
          }
          .el-button {
            border-radius: 30px;
            // padding: 12px 35px;
            background-color: #4e80f5;
            font-size: 16px;
          }
        }
      }
      .txt:hover {
        background-color: #eef3fe;
        .txtLeft {
          .txtUp {
            color: #4e80f5;
            font-weight: 600;
          }
        }
        .txtLeftDTJ {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            .txtUp {
              color: #4e80f5;
              font-weight: 600;
            }
          }
        }
        .txtRight {
          .el-button {
            border-radius: 30px;
            // padding: 12px 35px;
            background-color: #4e80f5;
            font-size: 16px;
          }
        }
        .txtRightDTJ {
          display: flex;
          align-items: center;
          margin-right: 10px;
          .el-button {
            border-radius: 30px;
            // padding: 12px 35px;
            background-color: #4e80f5;
            font-size: 16px;
          }
        }
      }
    }

    .assessement_form{
      width: 90%;
      height: 60px;
      background: #f2f2f2;
      border-radius: 4px;
      margin: 10px auto;
      position: relative;
      display: flex;
      justify-content: space-between;

      .assessement_left{
        font-size: 16px;
        font-weight: bold;
        color: #4e80f5;
        margin-left: 15px;
        margin-top: 10px;
        p{
          font-size: 12px;
          color: #999999;
          font-weight: normal;
          margin-top: 5px;
        }
      }
      .assessement_right{
        margin-top: 12px;
        margin-right: 15px;


        .assessement_title{
          font-size: 14px;
          line-height: 36px;
          text-align: center;
          background: #4e80f5;
          color: #ffffff;
          padding: 8px 15px;
          border-radius: 8px;
          cursor: pointer;


        }

      }
      //.assessement_title{
      //  height: 30px;
      //  line-height: 30px;
      //  font-size: 14px;
      //  cursor: pointer;
      //  color: #ffffff;
      //  margin-left: 20px;
      //  margin-bottom: 15px;
      //  background-color: #4e80f5;
      //  text-align: center;
      //  padding: 10px 20px;
      //  border-radius: 40px;
      //
      //}

    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px 0;
  }
}
.el-progress {
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
  align-items: center;
}
/deep/.el-progress__text {
  font-size: 14px !important;
  margin-left: 20px;
  width: 100px;
}
/deep/.el-pagination.is-background .el-pager li {
  background-color: #fff;
}
/deep/.el-pagination.is-background .btn-prev {
  background-color: #fff;
}
/deep/.el-pagination.is-background .btn-next {
  background-color: #fff;
}
/deep/.el-empty {
  padding: 60px 0;
}
/deep/.el-progress-bar__inner {
  background-color: #4e80f5;
}
/deep/.el-button--primary {
  background-color: #4e80f5;
  border: 0;
}
/deep/.el-button--primary:hover {
  background-color: #4e80f5;
  border: 0;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4e80f5;
}
</style>